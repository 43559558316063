// * echarts组件
<template>
  <div class="chart-dom" ref="chartDom"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'Charts',

  components: {},

  props: {
    option: {
      required: true,
      type: Object,
    },
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: 400,
    },
    unit: {
      type: String,
      default: 'px',
      validator: val => ['px', 'vw'].indexOf(val) >= 0,
    },
  },

  data() {
    return {
      myChart: null,
    }
  },

  computed: {
    unitWidth() {
      return this.unit === 'vw'
        ? `${(this.width / 1920) * 100}vw`
        : `${this.width}px`
    },
    unitHeight() {
      return this.unit === 'vw'
        ? `${(this.height / 1920) * 100}vw`
        : `${this.height}px`
    },
  },

  watch: {
    option: {
      handler(op) {
        if (this.myChart) this.myChart.dispose() // 先销毁实例

        this.myChart = echarts.init(this.$refs.chartDom)
        this.myChart.setOption(op)
      },
      deep: true,
    },
    unitWidth() {
      this.chartResize()
    },
    unitHeight() {
      this.chartResize()
    },
  },

  created() {},

  mounted() {
    this.myChart = echarts.init(this.$refs.chartDom)

    // 使用刚指定的配置项和数据显示图表。
    this.myChart.setOption(this.option)

    window.addEventListener('resize', this.chartResize)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.chartResize)
      this.myChart && this.myChart.dispose()
      this.myChart = ''
    })
  },

  methods: {
    chartResize() {
      if (window.innerWidth > 1280 && this.myChart) this.myChart.resize()
    },
  },
}
</script>
<style lang="scss" scoped>
.chart-dom {
  width: 100%;
  height: 100%;
}
</style>
