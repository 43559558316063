import config from '_config'
const screenshotURL = config.screenshotURL

class Equipment {
  constructor({
    id,
    name,
    state,
    isOnline,
    key,
    mac,
    camera,
    cameraBuiltin,
    localIp,
    dnsApi,
  }) {
    this.id = id
    this.name = name
    this.state = state // 1已绑定/3报修/-1未绑定
    this.cover = `${screenshotURL}/${key}.png`
    this.camera = camera
      ? camera.split(',').map(item => {
          const [url, name] = item.split('#_')
          return {
            name,
            url,
          }
        })
      : []
    this.cameraBuiltin = cameraBuiltin ? cameraBuiltin.split(',') : []
    this._key = key // 1已绑定/3报修/-1未绑定
    this._isOnline = isOnline // 0否/1是
    this.localIp = localIp // 内网ip
    this.dnsApi = dnsApi // 接口api
    this.mac = key
  }

  get isOnline() {
    return this._isOnline === '1'
  }

  updateCover() {
    this.cover = `${screenshotURL}/${this._key}.png?_t=${+new Date()}`
  }
}

export default Equipment
