// * 抽屉组件
<template>
  <Drawer
    v-model="isShow"
    :title="title"
    :mask-closable="true"
    :width="relWidth"
    :placement="placement"
    @on-close="onClose"
  >
    <div slot="header" class="header-class">
      <section class="commen-class">{{ title }}</section>
      <section v-if="equipmentName" class="equipment-class">
        {{ equipmentName }}
      </section>
    </div>
    <section class="divider"></section>
    <div class="drawer-content">
      <slot></slot>
    </div>

    <div class="drawer-footer">
      <slot name="button">
        <Button id="cancelButton" @click="onCancel" shape="circle" ghost>{{
          cancel
        }}</Button>
        <Button
          class="save-btn"
          type="primary"
          shape="circle"
          @click="onConfirm"
          >{{ confirm }}</Button
        >
      </slot>
    </div>
  </Drawer>
</template>

<script>
import { Drawer } from 'view-design'

export default {
  name: 'AsideDrawer',

  components: { Drawer },

  props: {
    value: {
      required: true,
      type: Boolean,
    },
    title: {
      type: String,
      default: '',
    },
    equipmentName: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 600,
    },
    confirm: {
      type: String,
      default() {
        return '保存'
      },
    },
    cancel: {
      type: String,
      default: '取消',
    },
    placement: {
      type: String,
      default: 'right',
      validator(val) {
        return ['left', 'right'].indexOf(val) >= 0
      },
    },
  },

  data() {
    return {}
  },

  computed: {
    isShow: {
      get() {
        return this.value
      },
      set(flag) {
        this.$emit('input', flag)
      },
    },
    relWidth() {
      // return (this.width / 1920) * screen.width
      return this.width
    },
  },

  watch: {},

  created() {},

  mounted() {},

  methods: {
    onCancel() {
      this.isShow = false
      this.$emit('on-cancel')
    },
    onConfirm() {
      this.$emit('on-confirm')
    },
    // 关闭抽屉
    onClose() {
      this.$emit('on-close')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-transfer-dom /deep/ .ivu-drawer {
  .header-class {
    display: flex;
  }
  .commen-class {
    font-size: 20px;
    font-weight: 600;
    color: #000000d9; //TODO 账号管理 分配设备 “分配设备“字体颜色
  }
  .divider {
    width: 100%;
    height: 1px;
    background: #f0f0f0; //TODO 信发库 新增信发内容 顶框底部边框背景颜色
  }
  .equipment-class {
    width: 380px;
    margin-left: 20px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 64px;
  }
  .ivu-drawer-header {
    height: 80px;
    line-height: 60px;
    background: #fff; //TODO 账号管理 新建账号顶部框背景颜色
    border-bottom: none;
    &-inner {
      font-size: 20px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
    }
  }
  .ivu-drawer-close {
    margin-top: 20px;
    .ivu-icon {
      font-size: 25px;
      font-weight: 600;
      color: #00000073; //TODO  功能页 未点击关闭页面 X号显示颜色

      &:hover {
        color: crimson; //TODO  功能页 点击关闭页面 X号显示颜色
      }
    }
  }
  .ivu-drawer-body {
    display: flex;
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    background: #fff; //TODO 账号管理 新建账号下框背景颜色

    .drawer-content {
      flex: 1;
      overflow: auto;
      padding: 0 40px;
    }
    .drawer-footer {
      width: 100%;
      height: 100px;
      margin-top: auto;
      flex-shrink: 0;

      background: #fff; //TODO 账号管理 新建账号底部框背景颜色

      border-top: 1px solid #d9d9d9; //TODO 账号管理 新建账号底部框边框颜色
      padding: 10px 16px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 30px;

      .save-btn {
        //TODO 确认按钮颜色
        margin-left: 50px;
        color: #ffffff;
        background-color: #0f8dff;
        border-color: #0f8dff;
        &:hover {
          //TODO 编辑通道 确认按钮选择时 背景颜色
          background-color: #0f8dff;
          border-color: #0f8dff;
          opacity: 0.8;
        }
      }
      #cancelButton {
        //TODO 编辑通道 取消按钮背景颜色
        border-color: #0f8dff !important;
        color: #0f8dff !important;
        background-color: #fff;

        &:hover {
          //TODO 编辑通道 取消按钮 选择时背景颜色
          color: #ffffff !important;
          background-color: #0f8dff !important;
          border-color: #0f8dff !important;
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
