/**
 * * api映射工具
 * @author fanzhongxu
 */

import { apiMethods } from '_api'
import getTypeOf from './get_type_of'

function validateParams(...args) {
  if (args.length === 0) throw new Error('请填写api')
  else if (args.length === 1 && getTypeOf(args[0]) !== 'array')
    throw new Error('类型错误，应该接受数组 mapApi(array)')
  else if (
    args.length === 2 &&
    (getTypeOf(args[0]) !== 'string' || getTypeOf(args[1]) !== 'array')
  )
    throw new Error('类型错误，mapApi(string, array)')
}

/**
 * @description 映射 api 方法
 * @param {Array} namespace 可选，需要映射的api方法列表
 * @param {Array} apis 必填，需要映射的api方法列表
 * @returns {方法名: 方法函数}
 */
export default (...args) => {
  validateParams(args)
  const methods =
    args.length === 1 ? apiMethods : apiMethods[args[0] + '_module'] || {}
  const apis = args.length === 1 ? args[0] : args[1]

  return apis.reduce((obj, cur) => {
    obj[cur] = methods[cur]
    return obj
  }, {})
}
