<template>
  <div>
    <!-- 日期 && 时间选择器 -->
    <!-- 一次 -->
    <div v-if="repeatNum === '0' && !period">
      <el-date-picker
        v-model="modelDate"
        type="datetime"
        placeholder="请选择日期"
        @change="dateChange"
        format="yyyy-MM-dd HH:mm"
        confirm
      ></el-date-picker>
    </div>
    <!-- 每天 && 每周 && 每月 -->
    <div v-if="repeatNum !== '0' && !period">
      <el-time-picker
        v-model="modelTime"
        type="time"
        format="HH:mm"
        :editable="false"
        placeholder="请选择时间"
        @change="timeChange"
        confirm
      ></el-time-picker>
    </div>
    <!-- 时间段 -->

    <!-- 一次 -->
    <div v-if="repeatNum === '0' && period">
      <el-date-picker
        v-model="modelDate"
        type="datetimerange"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        placeholder="请选择日期"
        @change="periodDate"
        format="yyyy-MM-dd HH:mm"
        confirm
      ></el-date-picker>
    </div>
    <!-- 每天 && 每周 && 每月 -->
    <div v-if="repeatNum !== '0' && period">
      <el-time-picker
        is-range
        v-model="value4"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        placeholder="选择时间范围"
        format="HH:mm"
        @change="periodTime"
      >
      </el-time-picker>
    </div>
  </div>
</template>
<script>
export default {
  name: 'timeDateSelect',
  props: {
    period: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    repeatNum: {
      type: String,
    },
  },
  data() {
    return {
      value4: '',
      modelDate: '',
      modelTime: '',
    }
  },
  watch: {},
  computed: {},
  methods: {
    // 单日期选择
    dateChange(val) {
      const date = this.getDateTimestamp(val)
      // this.getChangeTime(date)
      this.$emit('on-date-change', date)
    },
    // 单时间选择
    timeChange(val) {
      const date = this.getDateChange(val)
      const timeDate = this.getTimeTimestamp(date)
      this.$emit('on-time', timeDate)
    },
    // 时间段 日期 && 时间
    periodDate(data) {
      const time1 = this.getDateTimestamp(data[0])
      const time2 = this.getDateTimestamp(data[1])
      this.$emit('on-period-date', time1, time2)
    },
    periodTime(data) {
      const date1 = this.getDateChange(data[0])
      const timeDate1 = this.getTimeTimestamp(date1)
      const date2 = this.getDateChange(data[1])
      const timeDate2 = this.getTimeTimestamp(date2)
      this.$emit('on-period-time', timeDate1, timeDate2)
    },
    // 时间格式转化
    getDateChange(data) {
      const date = new Date(data)
      const hour = date.getHours()
      const minute = date.getMinutes()
      const h = hour < 10 ? '0' + hour : hour
      const min = minute < 10 ? '0' + minute : minute
      return h + ':' + min
    },
    // 获取时间的时间戳
    getTimeTimestamp(time) {
      const timeDate = '1970-01-01'
      const combinationDate = timeDate + ' ' + time + ':' + '00'
      const date = new Date(combinationDate)
      const timestamp = date.getTime()
      return timestamp
    },
    // 获取日期的时间戳
    getDateTimestamp(time) {
      const date = new Date(time)
      const timestamp = date.getTime()
      return timestamp
    },

    setDate(timestamp) {
      const date = new Date(timestamp)
      this.value4 = date
      this.modelDate = date
      this.modelTime = date
    },
  },
  mounted() {},
  created() {},
}
</script>
<style lang="less" scoped>
&/deep/ .el-date-editor {
  // width: 330px;
  height: 46px;
  background: rgba(41, 55, 88, 1);
  box-shadow: 0px 0px 10px 0px rgba(12, 24, 52, 1);
  border-radius: 23px;
  border: 1px solid rgba(63, 83, 130, 1);
  .el-input__inner {
    border-radius: 23px;
    height: 46px;
    border: 1px solid rgba(63, 83, 130, 1);
  }
  .el-input__icon {
    height: 46px;
    display: flex;
    align-items: center;
  }
  .el-range-input {
    background: rgba(41, 55, 88, 1);
  }
  .el-range-separator {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
