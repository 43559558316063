<template>
  <Upload
    ref="fileUpload"
    :action="uploadUrl"
    :headers="localHeaders"
    :multiple="multiple"
    :paste="paste"
    :disabled="isDisabled"
    :data="uploadData"
    :format="format"
    :show-upload-list="false"
    :before-upload="onStart"
    :on-progress="onProgress"
    :on-success="onSuccess"
    :on-error="onError"
    :on-format-error="onFormatError"
    :on-exceeded-size="onExceededSize"
  >
    <slot></slot>
  </Upload>
</template>

<script>
import config from '_config'
import getType from '_enums/fileTypeMap'
import { mapState } from 'vuex'
export default {
  name: 'fileUpload',
  data() {
    return {
      disabled: false,
      upData: {},
    }
  },
  props: {
    headers: {
      type: Object,
    },
    multiple: {
      type: Boolean,
    },
    paste: {
      type: Boolean,
    },
    // disabled: {
    //   type: Boolean,
    // },
    data: {
      type: Object,
    },
    format: {
      type: Array,
    },
  },
  computed: {
    ...mapState({
      ticket: state => state?.loginData?.ticket,
    }),
    uploadUrl() {
      return config.baseURL + 'information/upload-file'
    },
    isDisabled: {
      get() {
        return this.disabled
      },
      set(value) {
        this.disabled = value
      },
    },
    localHeaders() {
      return Object.assign({}, this.headers || {}, { ticket: this.ticket })
    },
    uploadData() {
      return Object.assign(this.upData, this.data)
    },
  },
  methods: {
    onStart(file) {
      this.isDisabled = true
      this.$refs.fileUpload.clearFiles()
      if (file.size > 1024 * 1024 * 500) {
        this.$alertError('文件不能大于500M')
        return false
      }
      // 获取参数类型
      const reg = /.\w*$/
      const fileName = file.name
      const matchRes = fileName.match(reg)
      let suffixName = matchRes[0] ? matchRes[0].replace(/^./, '') : ''
      suffixName = suffixName.toLowerCase()
      if (!suffixName) return false

      const type = getType(suffixName)
      if (!type) return false
      this.$set(this.upData, 'type', type)

      console.log(type, 'ddjsljfdljflksd')
      this.$emit('on-start', file)
      this.$Spin.show({
        render: h => {
          return h('div', [
            h('Icon', {
              class: 'spin-icon-load',
              props: {
                type: 'ios-loading',
                size: 18,
              },
            }),
            h('div', '上传中...'),
          ])
        },
      })
    },
    onProgress(event, file, fileList) {
      this.$emit('on-progress', event.percent, file, fileList)
    },
    onSuccess(response, file, fileList) {
      this.isDisabled = false
      const url = response.name
      this.$refs.fileUpload.clearFiles()
      this.$alertSuccess('上传成功')
      this.$emit('on-success', url, file, fileList)
      this.$Spin.hide()
    },
    onError(error, file, fileList) {
      this.isDisabled = false
      this.$alertError('上传失败,请重新上传')
      this.$refs.fileUpload.clearFiles()
      this.$emit('on-err', error, file, fileList)
      this.$Spin.hide()
    },
    onFormatError(file, fileList) {
      this.isDisabled = false
      this.$refs.fileUpload.clearFiles()
      this.$alertError(`文件格式不正确, 只支持${this.format.join('、')}`)
      this.$emit('on-format-error', '', '', '')
      this.$Spin.hide()
    },
    onExceededSize(file, fileList) {
      this.$alertError('文件过大')
      this.$Spin.hide()
    },
  },
  mounted() {},
}
</script>

<style scoped></style>
