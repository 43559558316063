export default {
  path: '/application',
  redirect: 'library',
  component: () => import('_views/application/index'),
  children: [
    {
      path: 'library',
      meta: {
        auth: true,
        title: '应用库',
      },
      component: () => import('_views/application/library'),
    },
    {
      path: 'detail',
      meta: {
        auth: true,
        title: '下发记录',
      },
      component: () => import('_views/application/detail'),
    },
    {
      path: 'distribute/:id',
      name: 'DistributeApplication',
      meta: {
        auth: true,
        title: '下发应用',
        roles: ['SCHOOL', 'EQUIPMENT'],
      },
      component: () => import('_views/application/distribute'),
      props: true,
    },
  ],
}
