/**
 * * Vuex配置文件
 */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { sStorage as storageUtil } from '../library/storage'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

// 导入模块
import home from './modules/home'
import equipment from './modules/equipment'
import software from './modules/software'
import patrolStore from './modules/patrol'

Vue.use(Vuex)

// 持久化配置
const vuePersistenct = createPersistedState({
  key: 'state',
  paths: [
    'loginData',
    'equipment.userIdSetting',
    'equipment.deviceLogs',
    'patrol.equipments',
  ], // 需要持久化的state数据(如果是modules数据，使用 模块名.数据 )
  storage: {
    getItem: key => storageUtil.getItem(key),
    setItem: (key, value) => storageUtil.setItem(key, value),
    removeItem: key => storageUtil.removeItem(key),
  },
})

const store = new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',
  actions,
  getters,
  mutations,
  state,
  plugins: [vuePersistenct],
  modules: {
    home,
    equipment,
    patrol: patrolStore,
    software,
  },
})

export default store
