<!-- 页面标题组件 -->
<template>
  <Breadcrumb separator=">">
    <Breadcrumb-item v-for="item of data" :key="item.name" :to="item.href">{{
      item.name
    }}</Breadcrumb-item>
  </Breadcrumb>
</template>

<script>
export default {
  name: 'PageTitle',

  components: {},

  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {}
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {},
}
</script>

<style lang="less" scoped>
.demo-breadcrumb-separator {
  color: #ff5500;
  padding: 0 5px;
}
/*& /deep/ .ivu-breadcrumb-item-link {
  height: 27px;
  font-size: 16px;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(119, 129, 145, 1);
  line-height: 22px;
}
& /deep/ .icon-jiantouyou {
  font-size: 12px;
  color: #778191;
}*/
</style>
