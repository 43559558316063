/**
 * * 枚举资源配置文件
 */

// * 用户角色
export const rolesEnum = {
  ADMIN: 1, // 超级管理员
  AREA: 2, // 区域管理员
  SCHOOL: 3, // 学校管理员
  EQUIPMENT: 4, // 设备管理员
  CLASSADMIN: 5, // 班牌管理员
  CLASSVISTOR: 6, // 班牌统计专员
  1: 'ADMIN',
  2: 'AREA',
  3: 'SCHOOL',
  4: 'EQUIPMENT',
  5: 'CLASSADMIN',
  6: 'CLASSVISTOR',
}
export const rolesNameEnum = {
  '1': '超级管理员',
  '2': '区域管理员',
  '3': '学校管理员',
  '4': '设备管理员',
  '5': '班牌管理员',
  '6': '班牌统计员',
  超级管理员: '1',
  区域管理员: '2',
  学校管理员: '3',
  设备管理员: '4',
  班牌管理员: '5',
  班牌统计员: '6',
}

export const monthsEnum = [
  {
    title: '一月',
    value: '1',
  },
  {
    title: '二月',
    value: '2',
  },
  {
    title: '三月',
    value: '3',
  },
  {
    title: '四月',
    value: '4',
  },
  {
    title: '五月',
    value: '5',
  },
  {
    title: '六月',
    value: '6',
  },
  {
    title: '七月',
    value: '7',
  },
  {
    title: '八月',
    value: '8',
  },
  {
    title: '九月',
    value: '9',
  },
  {
    title: '十月',
    value: '10',
  },
  {
    title: '十一月',
    value: '11',
  },
  {
    title: '十二月',
    value: '12',
  },
]

export const videoFormat = ['mp4', 'MP4']

export const imgFormat = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG']
