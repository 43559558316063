import Vue from 'vue'
import Component from './index.vue'

class AssetsViewer {
  constructor() {
    if (!AssetsViewer.instance) {
      const Instance = Vue.extend({
        name: 'AssestsViewerWrapper',
        data() {
          return { type: 'img', src: '.', show: false }
        },
        render(h) {
          return h(Component, {
            props: {
              type: this.type,
              src: this.src,
              value: this.show,
            },
            on: {
              input: val => (this.show = val),
            },
          })
        },
      })
      this.comInstance = new Instance()
      this.component = this.comInstance.$mount()
      this.dom = this.component.$el
      document.body.appendChild(this.dom)
      AssetsViewer.instance = this
    }
    return AssetsViewer.instance
  }

  show(options) {
    const { type, src } = options
    Object.assign(this.comInstance.$data, { type, src })
    this.comInstance.$data.show = true
  }

  hide() {
    this.comInstance.$data.show = false
  }
}

export default AssetsViewer
