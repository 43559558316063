import myXss from '_library/xss'

/**
 * * 全局自定义指令
 */
const directives = {
  focus: {
    // 当被绑定的元素插入到 DOM 中时……
    inserted: function(el) {
      // 聚焦元素
      el.focus()
    },
  },
  xss: {
    inserted: function(el, binding, vnode, oldVnode) {
      el.innerHTML = myXss.process(binding.value)
    },
    update(el, binding, vnode, oldVnode) {
      if (binding.value !== binding.oldValue) {
        el.innerHTML = myXss.process(binding.value)
      }
    },
  },
}

export default directives
