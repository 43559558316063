// * 图片组件
<template>
  <div
    class="image-wrapper"
    :style="{
      width: width ? `${width}px` : 'auto',
      height: height ? `${height}px` : 'auto',
    }"
  >
    <img
      v-show="status === StatusEnum.LOADED"
      :alt="alt"
      v-on="$listeners"
      ref="img"
    />
    <slot name="loading"></slot>
    <slot name="failed" v-if="status === StatusEnum.FAILED">
      <Empty v-bind="emptyInfo" />
    </slot>
  </div>
</template>

<script>
const StatusEnum = {
  LOADING: '0',
  LOADED: '1',
  FAILED: '2',
}
export default {
  name: 'ImageWrap',
  props: {
    src: String,
    alt: {
      type: String,
      default: 'img',
    },

    width: Number,
    height: Number,

    emptyInfo: {
      type: Object,
      // default: () => ({
      //   text: '',
      //   imgSrc: '',
      //   size: '',
      // }),
    },
  },
  data: function() {
    return {
      StatusEnum,
      status: StatusEnum.LOADING,
    }
  },

  watch: {
    src(src) {
      if (src) this.renderImg()
      else this.status = StatusEnum.FAILED
    },
  },

  mounted() {
    if (this.src) this.renderImg()
    else this.status = StatusEnum.FAILED
  },

  methods: {
    renderImg() {
      const src = this.src
      this.status = StatusEnum.LOADING
      if (!src) return
      const img = new Image()
      img.onerror = () => {
        this.status = StatusEnum.FAILED
      }
      img.onload = () => {
        this.status = StatusEnum.LOADED
        this.$nextTick(() => {
          this.$refs.img.src = src
        })
      }
      img.src = src
    },
  },
}
</script>

<style lang="scss" scoped>
.image-wrapper {
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
