import { lStorage as storageUtil } from '_library/storage'
import config from '_config/'

const mutations = {
  // * 登录
  login(state, data) {
    state.loginData = {
      isLogin: true,
      userInfo: data,
      ticket: data.ticket,
    }
  },

  // * 登出
  logout(state) {
    state.loginData = {
      isLogin: false,
      userInfo: null,
      ticket: '',
    }
    storageUtil.removeItem(`${config.sys_name}_cache`)
  },

  // * 更新用户信息
  setUserInfo(state, userInfo) {
    state.loginData.userInfo = userInfo
  },

  // * 设置spinning
  setSpinning(state, flag) {
    state.spinning = flag
  },
}

export default mutations
