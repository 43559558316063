// import { $get, $post, $patch, $put, $delete } from '_library/ajax'
import { $get } from '_library/ajax'

const list = {
  'get-region-all': '/region/get-region-all',
}

const methods = {
  /* 根据名称查询申请单 */
  getAllRegion: data => $get('get-region-all', data),
}

export default { list, methods }
