// * 表格列表组件
<template>
  <div class="section-list">
    <template v-if="type === 'table'">
      <div class="section-title" v-if="title && caption">
        {{ caption }}：{{ title }}
      </div>
      <i-table
        :columns="columns"
        :data="tableData"
        :height="height"
        v-if="tableData.length > 0"
        @on-selection-change="onSelectionChange"
      >
        <template #no-data-tip>
          <slot name="empty">
            <!-- <EmptyBox /> -->
            no-data
          </slot>
        </template>
      </i-table>
      <noData v-else />
      <slot name="footer"></slot>
    </template>

    <template v-if="type === 'custom'">
      <slot :data="tableData"></slot>
    </template>

    <div :class="['pager-wrapper', `pos-${pagePosition}`]">
      <Pager
        v-if="tableData.length > 0"
        class="section-list-pager"
        v-show="pageBar"
        :page-size="pageData.pageSize"
        :current.sync="pageData.pageNumber"
        :total="pageData.total"
        @on-change="search"
        @on-page-size-change="onPageSizeChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionList',

  props: {
    type: {
      type: String,
      validator: val => ['table', 'custom'].indexOf(val) >= 0,
      default: 'table',
    },
    api: {
      type: String,
    },
    params: {
      type: Object,
    },
    columns: {
      required: true,
      type: Array,
    },

    data: {
      type: Array,
    },
    autoLoad: {
      type: Boolean,
      default: true,
    },
    memorySelection: {
      type: Boolean,
      default: true,
    },
    pageBar: {
      type: Boolean,
      default: false,
    },
    pagePosition: {
      type: String,
      validator: val => ['left', 'center', 'right'].indexOf(val) >= 0,
      default: 'right',
    },
    title: {
      type: String,
      default() {
        return ''
      },
    },
    caption: {
      type: String,
      default() {
        return ''
      },
    },
    height: {
      type: Number,
    },
  },

  data() {
    return {
      tableData: this.data || [],
      tableParams: Object.assign(
        {
          page: {
            pageNumber: 1,
            pageSize: 10,
            total: 0,
          },
        },
        this.params || {}
      ),
      selections: [],
    }
  },

  computed: {
    pageData() {
      return this.tableParams.page
    },
    showPager() {
      return (
        this.pageData.total && this.pageData.total / this.pageData.pageSize > 1
      )
    },
  },

  watch: {
    data: {
      handler(data) {
        this.tableData = data
      },
      deep: true,
    },
    params: {
      handler(data) {
        Object.assign(this.tableParams, data || {})
      },
      deep: true,
    },
  },

  created() {
    if (this.api && this.autoLoad) this.search()
  },

  mounted() {},

  methods: {
    search() {
      this.$get(this.api, this.tableParams).then(res => {
        if (
          res.result &&
          res.result.length <= 0 &&
          this.tableParams.page.pageNumber > 1
        ) {
          this.tableParams.page.pageNumber -= 1
          this.search()
          return
        }
        this.tableData = this.mapMFAState(res.result) || []
        this.tableParams.page.total = res.totalCount || 0
        // this.tableParams.page.pageSize = res.pageSize
        this.tableParams.page.pageNumber = res.currentPageNo || 1

        if (this.memorySelection) this.setCheck()

        this.$emit('on-load', res, this.tableParams)
      })
    },

    onSelectionChange(selection) {
      if (this.memorySelection) {
        // 先从selection删除当前页数据
        this.selections = this.selections.filter(s =>
          this.tableData.every(t => t.id !== s.id)
        )

        // 在填补当前页数据
        this.selections = this.selections.concat(selection)

        // 设置选中状态
        this.setCheck()

        this.$emit('on-select', this.selections)
      } else this.$emit('on-select', selection)
    },

    // 设置选中状态
    setCheck() {
      this.tableData.forEach(t => {
        if (this.selections.some(s => s.id === t.id))
          this.$set(t, '_checked', true)
        else this.$set(t, '_checked', false)
      })
    },
    clearSelected() {
      if (this.memorySelection) {
        this.selections = this.selections.filter(s =>
          this.tableData.every(t => t.id !== s.id)
        )
        this.setCheck()
      }
      this.tableData.forEach(t => {
        this.$set(t, '_checked', false)
      })
    },
    onPageSizeChange(pageSize) {
      this.tableParams.page.pageSize = pageSize
      this.search()
    },
    mapMFAState(result) {
      return result.map(item => {
        item.mfaState = item.mfaState === '0' ? '未开启' : '已开启'
        return item
      })
    },
  },
}
</script>

<style lang="scss">
.ivu-table {
  &:before {
    background: transparent !important;
  }
  background: transparent;
  td {
    background-color: transparent;
    border: none !important;
  }
  .ivu-table-header {
    th {
      background: transparent;
      border: none;
      color: #79829c;
    }
  }
  .ivu-table-body {
    table {
      border-collapse: separate;
      border-spacing: 0px 16px;
    }
    .ivu-table-tbody {
      .ivu-table-row {
        background: rgb(255, 255, 255);
        border-radius: 4px;
        border: none;
        .ivu-table-cell {
          text-overflow: unset;
        }
        &:hover {
          //TODO:账号管理界面 选择框背景颜色
          background: #e3f4fc;

          .ivu-tooltip-rel {
            background: #e3f4fc; //TODO 设备管理 下发记录 图标背景颜色
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              //TODO 设备管理 下发记录 选择时 图标背景颜色
              color: #0f8dff;
              opacity: 0.8;
            }
          }

          //box-shadow: 3px 6px 24px 0px rgba(5, 14, 41, 0.68);
        }
        td {
          background: transparent;
          border: none;
          color: rgb(0, 0, 0);
        }
        .ivu-checkbox-inner {
          &::after {
            border-spacing: 0 !important;
          }
        }
      }
    }
  }
}

.pager-wrapper {
  display: flex;
  margin-top: 20px;

  &.pos-center {
    justify-content: center;
  }
  &.pos-right {
    justify-content: flex-end;
  }
}
</style>
