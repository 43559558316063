<template>
  <div class="video-player">
    <div class="mask"></div>
    <div v-if="!showFlashErr">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
        @canplay="onPlayerCanplay($event)"
        @loadeddata="onPlayerLoadeddata($event)"
        @canplaythrough="onPlayerCanplaythrough($event)"
      ></video-player>
      <div class="loading" v-if="showLoading">
        <Spin>
          <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          <div class="loadding-word">加载中...</div>
        </Spin>
      </div>
    </div>
    <div class="flash-err" v-else>
      <img src="~_images/cos/flashLiveErr.png" alt="" />
    </div>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
import 'videojs-flash'
// import 'video.js/dist/lang/zh-CN'
import { Spin } from 'view-design'

export default {
  name: 'VideoPlayer',
  components: { videoPlayer, Spin },
  data() {
    return {
      showLoading: false, // 显示加载中
      showFlashErr: false, // flash异常显示
      playerOptions: {
        height: '800px',
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: true,
        muted: false,
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto',
        language: 'zh-CN',
        // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        // fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: '',
            src: '', // url地址
          },
        ],
        techOrder: ['html5', 'flash'],
        poster: '', // 你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },
    }
  },
  props: {
    videoUrl: {
      type: String,
    },
    coverUrl: {
      type: String,
    },
    souType: {
      type: String,
      default() {
        return 'video/mp4'
      },
    },
  },
  computed: {
    playerUrl() {
      return this.videoUrl
    },
    player() {
      return this.$refs.videoPlayer
    },
    sourcesType() {
      return this.souType
    },
  },
  methods: {
    onPlayerLoadeddata(player) {
      if (this.souType === 'video/mp4') {
        this.showLoading = true
      } else if (this.souType === 'rtmp/flv') {
        this.showLoading = false
      }
      console.log(this.showLoading, 'onPlayerLoadeddata')
    },
    onPlayerCanplay() {
      if (this.souType === 'video/mp4') {
        this.showLoading = false
      }
      console.log(this.showLoading, 'onPlayerCanplay')
    },
    onPlayerCanplaythrough(player) {
      console.log(player, 'onPlayerCanplaythrough')
      if (this.souType === 'rtmp/flv') {
        this.showLoading = true
      }
    },
    hasUsableFlash() {
      var flashObj
      if (typeof window.ActiveXObject !== 'undefined') {
        flashObj = new window.ActiveXObject('ShockwaveFlash.ShockwaveFlash')
      } else {
        flashObj = navigator.plugins['Shockwave Flash']
      }
      return !!flashObj
    },
  },
  mounted() {
    this.playerOptions.sources[0].type = this.sourcesType
    this.playerOptions.sources[0].src = this.videoUrl
    this.playerOptions.poster = this.coverUrl
    if (this.souType === 'rtmp/flv') {
      var result = this.hasUsableFlash()
      if (!result) {
        this.showFlashErr = true
      }
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  watch: {
    videoUrl() {
      this.playerOptions.sources[0].src = this.playerUrl
      this.$forceUpdate()
    },
    souType() {
      this.playerOptions.sources[0].type = this.souType
      this.$forceUpdate()
    },
  },
}
</script>

<style scoped>
/deep/ .video-js .vjs-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
.video-player {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading {
  position: absolute;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: rgba(199, 205, 218, 1);
  line-height: 22px;
}
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loadding-word {
  margin-top: 10px;
}
.flash-err {
  width: 721px;
  height: 315px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 20px 0px rgba(121, 145, 182, 0.58);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/deep/ .vjs-error .vjs-error-display .vjs-modal-dialog-content {
  padding-top: 550px !important;
  font-size: 30px !important;
}
</style>
