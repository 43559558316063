<template>
  <Layout>
    <Sider :width="230" class="layout-left-sider">
      <AsideMenu />
    </Sider>

    <Layout class="layout-inner">
      <Header>
        <TopHeader />
      </Header>
      <Content>
        <slot></slot>
      </Content>
    </Layout>
  </Layout>
</template>

<script>
import AsideMenu from './components/_aside-menu'
import TopHeader from '../common/_top-header'
export default {
  name: 'BaseLayout',

  components: { AsideMenu, TopHeader },

  props: {
    // current: {
    //   required: true,
    //   type: String,
    // },
  },

  data() {
    return {}
  },

  computed: {},

  watch: {},

  created() {},

  mounted() {},

  methods: {},
}
</script>

<style lang="scss" scoped>
.ivu-layout-sider {
  // TODO 去掉侧边栏投影 box-shadow: 0px 2px 20px 0px rgba(14, 27, 57, 0.38);

  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 101;
}

.layout-inner {
  padding-left: 230px;
  overflow-x: inherit !important; //TODO 缩放时拉动滑条显示全部
  .ivu-layout-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding-left: 230px;
    z-index: 100;
    box-shadow: 0px 2px 10px 0px #d2d5de; //TODO 页面 顶部框 阴影颜色
  }

  .ivu-layout-content {
    padding-top: 80px;
    min-width: 1050px;
    box-sizing: border-box;
    min-height: 100vh;
  }
}
</style>
