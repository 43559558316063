export default {
  path: '/equipment',
  redirect: '/equipment/account',
  component: () => import('_views/equipment/index'),
  children: [
    {
      path: 'account',
      meta: {
        auth: true,
        title: '设备管理',
      },
      component: () => import('_views/equipment/main'),
    },
    {
      path: 'equipmentDatailIndex',
      meta: {
        auth: true,
        title: '设备日志',
      },
      component: () => import('_views/equipment/equipmentDatailIndex/index'),
    },
    {
      path: 'equipmentLog',
      meta: {
        auth: true,
        title: '日志详情',
      },
      component: () =>
        import('_views/equipment/equipmentDatailIndex/equipmentLog'),
    },
    {
      path: 'equipmentIndex',
      meta: {
        auth: true,
        title: '设备详情',
      },
      component: () =>
        import('_views/equipment/equipmentDatailIndex/equipmentIndex'),
    },
    {
      path: 'sendRecord',
      name: 'SendRecord',
      meta: {
        auth: true,
        title: '下发记录',
      },
      component: () => import('_views/equipment/sendRecord/index'),
    },
    {
      path: 'tourClass',
      meta: {
        auth: true,
        title: '巡课模式',
      },
      component: () => import('_views/equipment/tourClass'),
    },
  ],
}
