import home from './home'
import school from './school'
import account from './account'
import equipment from './equipment'
import cos from './cos'
import after from './after'
import equipmentSet from './equipmentSet'
import policyManagement from './policyManagement'
import applicationManage from './application'
import softwareManage from './softwareManage'
import patrol from './patrol'
import classCard from './classcard'
import manage from './manage'
import shuangLiuBind from './shuangLiuBind'
import dataException from './dataException'
export default [
  {
    path: '/',
    children: [], // 各模块都挂载在'/'下
    redirect: '/home',
  },

  home,
  school,
  account,
  equipment,
  cos,
  after,
  equipmentSet,
  policyManagement,
  applicationManage,
  softwareManage,
  patrol,
  classCard,
  manage,
  shuangLiuBind,
  dataException,
  {
    path: '/remote',
    name: 'Remote',
    meta: {
      auth: true,
      title: '远程控制',
    },
    component: () => import('_views/remote'),
  },
  {
    path: '/password',
    name: 'ModifyPassword',
    meta: {
      auth: true,
      title: '修改密码',
    },
    component: () => import('_views/ModifyPassword'),
  },
  {
    path: '/systemMessage',
    name: 'SystemMessage',
    meta: {
      auth: true,
      title: '系统消息',
    },
    component: () => import('_views/SystemMessage'),
  },
  {
    path: '/profiles',
    name: 'Profiles',
    meta: {
      auth: false,
      title: '登录',
    },
    component: () => import('_views/Profiles'),
  },

  {
    path: '/login',
    name: 'Login',
    meta: {
      auth: false,
      title: '登录',
    },
    component: () => import('_views/Login'),
  },
  {
    path: '/403',
    meta: {
      auth: false,
      title: '403',
    },
    component: () => import('_views/NoAuthorization'),
  },
  {
    path: '/page',
    meta: {
      auth: false,
      title: 'page',
    },
    component: () => import('_views/PromptPage'),
  },
  {
    path: '*',
    meta: {
      auth: false,
      title: '404',
    },
    component: () => import('_views/NotFound'),
  },
]
