export default {
  path: '/software',
  redirect: '/software/software-library',
  component: () => import('_views/softwareManage/index'),
  children: [
    {
      path: 'software-library',
      meta: {
        auth: true,
        title: '软件库',
      },
      component: () => import('_views/softwareManage/softwareLibrary'),
    },
    {
      path: 'softdetail',
      meta: {
        auth: true,
        title: '软件详情',
      },
      component: () => import('_views/softwareManage/softdetail'),
    },
    {
      path: 'distribution-record',
      meta: {
        auth: true,
        title: '下发管理',
      },
      component: () => import('_views/softwareManage/distributionRecord'),
    },
    {
      path: 'distribution-soft',
      meta: {
        auth: true,
        title: '下发软件',
      },
      component: () => import('_views/softwareManage/distributionSoft'),
    },
    {
      path: 'adware-set',
      meta: {
        auth: true,
        title: '广告软件配置',
      },
      component: () => import('_views/softwareManage/adware-set'),
    },
    {
      path: 'popup-set',
      meta: {
        auth: true,
        title: '广告软件配置',
      },
      component: () => import('_views/softwareManage/popup-set'),
    },
  ],
}
