/**
 * * 获取变量类型
 * @author fanzhongxu
 */

/**
 * @description 获取变量类型
 * @param {any} variable 变量
 * @returns 类型
 */
export default function(variable) {
  const result = Object.prototype.toString
    .call(variable)
    .match(/\s(\w*)]$/)[1]
    .toLowerCase()

  return result
}
