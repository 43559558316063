// * empty
<template>
  <div :class="['empty-wrapper', size]">
    <img v-if="imgSrc" :src="imgSrc" alt="no-data" />
    <img v-else src="~_images/patrol/no-data.png" alt="no-data" />

    <span v-if="text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'Empty',

  components: {},

  props: {
    text: {
      type: String,
    },
    imgSrc: {
      type: String,
    },
    size: {
      type: String,
      validator: val => ['small', 'large'].indexOf(val) >= 0,
      default: 'small',
    },
  },

  data() {
    return {}
  },

  computed: {},

  watch: {},

  created() {},

  mounted() {},

  methods: {},
}
</script>

<style lang="scss" scoped>
.empty-wrapper {
  @include flex-column-center;
  height: 100%;

  &.small {
    img {
      width: 30%;
    }
  }

  &.large {
    img {
      width: 20%;
    }
    span {
      font-size: 20px;
      font-weight: 500;
      color: #000000d9; //TODO 巡课模式 获取桌面屏幕 图片下方字体颜色
      opacity: 0.5;
      margin-top: 21px;
    }
  }
}
</style>
