export { default as mapApi } from './lib/map_api'
export { default as getTypeOf } from './lib/get_type_of'
export { default as exportExcel } from './lib/export_excel'
export { default as homeExportExcel } from './lib/home_export_excel'
export { default as floatMath } from './lib/float_math'
export { default as formatDate } from './lib/format_date'
export { default as genUUID } from './lib/gen_uuid'
export { default as slowHash } from './lib/slow_hash'
export { default as deepClone } from './lib/deep_clone'
export { default as getVideoBase64 } from './lib/get_video_base64'
export { aesEncrypt, aesDecrypt } from './lib/aes'

export const getRoutesByAuth = (routes, auth, role) => {
  if (!routes) return []

  const result = []
  for (const route of routes) {
    const routeAuth = route?.meta?.auth
    const routeRoles = route?.meta?.roles

    if (
      !routeAuth ||
      (routeAuth === auth && (!routeRoles || routeRoles.includes(role)))
    )
      result.push({
        ...route,
        ...{ children: getRoutesByAuth(route.children, auth, role) },
      })
  }
  return result
}
export const webDownLoad = function(downData, downName) {
  if ('msSaveOrOpenBlob' in navigator) {
    // edge 和 ie10-11
    window.navigator.msSaveOrOpenBlob(downData, downName)
  } else {
    // Chrome,Firefox 等浏览器
    var href = URL.createObjectURL(downData) // 创建对象超链接
    const outFile = document.createElement('a')
    outFile.download = `${downName}` // 下载名称
    outFile.href = href // 绑定a标签
    outFile.click() // 模拟点击实现下载
    setTimeout(function() {
      // 延时释放
      URL.revokeObjectURL(downData) // 用URL.revokeObjectURL()来释放这个object URL
    }, 100)
  }
}
/**
 * @description JS颜色十六进制转换为rgb或rgba,
 * @author fanzhongxu
 * @param {*} sHex sHex为传入的十六进制的色值
 * @param {number} [alpha=1] alpha为rgba的透明度
 * @returns rgba值 返回的格式为 rgba（255，255，255，0.5）字符串
 */
export const colorRgba = (sHex, alpha = 1) => {
  // 十六进制颜色值的正则表达式
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  /* 16进制颜色转为RGB格式 */
  let sColor = sHex.toLowerCase()
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      var sColorNew = '#'
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    }
    //  处理六位的颜色值
    var sColorChange = []
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
    }
    // return sColorChange.join(',')
    // 或
    return 'rgba(' + sColorChange.join(',') + ',' + alpha + ')'
  } else {
    return sColor
  }
}

/**
 * @description 根据日期获取星期数
 * @param {Date} date 日期
 * @returns {string} 星期数
 */
export const getweekday = date => {
  var weekArray = ['日', '一', '二', '三', '四', '五', '六']

  var week = weekArray[new Date(date).getDay()] // 注意此处必须是先new一个Date

  return '星期' + week
}

/**
 * @description 时间转换
 * @author fanzhongxu
 * @param {*} ms 毫秒
 * @returns 格式化文本
 */
export const getTimeFromMS = ms => {
  const aMin = 60 * 1000
  const aHour = 60 * aMin
  const aDay = aHour * 24

  const day = (ms / aDay) | 0

  const hour = ((ms - day * aDay) / aHour) | 0

  const min = ((ms - day * aDay - hour * aHour) / aMin) | 0

  return `${day}天${hour}小时${min}分钟`
}
