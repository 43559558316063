// import { $get, $post, $patch, $put, $delete } from '_library/ajax'
import { $get, $post } from '_library/ajax'

const list = {
  'school-list': '/school/page-school',
  'insert-school': '/school/insert-school',
  'import-school-batch': '/school/import-school-batch',
  'delete-school-batch': '/school/delete-school-batch',
  'update-school': '/school/update-school',

  'get-school-list': '/school/get-school-list',
  'get-school-tree': '/school/get-school-tree',
  'get-user-role-school-list': '/school/get-user-role-school-list',
  'get-user-school-list': '/school/get-user-school-list',
  'get-school-user-distribution-equipment-list':
    '/equipment/get-school-user-distribution-equipment-list',
  'get-allot-school-list': '/yd-school-info/get-allot-school-list',
  'allot-user-school': '/yd-school-info/allot-user-school',
  'query-yd-school-info-list': '/yd-school-info/query-yd-school-info-page-list',
  'get-region-tree': '/yd-school-info/get-region-tree',
  'update-school-info': '/yd-school-info/update-school-info',
  'query-teacher-list': '/yd-statistics-teacher/query-teacher-list',
}

const methods = {
  fetchSchoolList: data => $get('school-list', data), // 获取学校列表（分页）
  insertSchool: data => $post('insert-school', data), // 添加学校
  importSchool: data => $post('import-school-batch', data), // 导入学校
  deleteSchool: data => $post('delete-school-batch', data), // 删除学校
  updateSchool: data => $post('update-school', data), // 编辑学校
  getSchoolList: data => $get('get-school-list', data), // 查询学校列表（非分页）
  getSchoolTree: data => $get('get-school-tree', data), // 查询学校列表（tree结构）
  getSchoolByUserRole: data => $get('get-user-role-school-list', data), // 根据用户角色查询学校
  getUserSchoolList: data => $get('get-user-school-list', data), // 获取用户相关的学校列表（分配学校专用）
  getydSchoolList: data => $get('get-allot-school-list', data),
  ydBindSchool: data => $post('allot-user-school', data),
  getSchoolUserDistributionEquipmentList: data =>
    $get('get-school-user-distribution-equipment-list', data), // 分配设备列表
  schoolList: data => $get('query-yd-school-info-list', data),
  regionTree: data => $get('get-region-tree', data),
  updateSchoolInfo: data => $post('update-school-info', data),
  queryTeacherList: data => $get('query-teacher-list', data),
}

export default { list, methods }
