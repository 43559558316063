/**
 * * 项目信息配置文件
 */
const mode = process.env.NODE_ENV

const baseURL =
  // mode === 'development' ? 'http://iot_dev2.skyworthedu.com.cn/' : '/'
  // mode === 'development' ? 'http://iot_test2.skyworthedu.com.cn/' : '/' // 测试环境
  // mode === 'development' ? 'http://172.24.115.15:8080/' : '/' // 本地环境
  // mode === 'development' ? 'http://172.24.115.148:8080/' : '/' // 本地环境
  mode === 'development' ? 'https://iot.skyworthedu.com.cn/' : '/' // 正式环境
const apiURL = baseURL + 'api/' // 正式环境
// const apiURL = baseURL // 本地环境
const config = {
  rootURL: baseURL,
  copyright: '',
  sys_name: 'cos_system',
  site: 'skyworth_cos_ui',
  env: mode,
  version: '1561356303806',
  base_bucket:
    mode === 'development' ? 'skyworth-cloud-sys' : 'skyworth-cloud-sys',
  pub_bucket:
    mode === 'development' ? 'skyworth-cloud-image' : 'skyworth-cloud-image',
  baseURL: apiURL,
  uploadURL: baseURL + 'information/',
  screenshotURL: baseURL + 'upload/screenshot/',
  serverURL: 'http://39.96.57.215:7771/information/',
}
export default config
