<template>
  <div :class="name">
    <svg class="icon" aria-hidden="true">
      <use :href="IconName"></use>
    </svg>
  </div>
</template>
<script>
import '_style/fonts/iconfont.js'
export default {
  name: 'IconSvg',
  components: {},
  props: {
    name: {
      type: String,
      default: () => {
        return ''
      },
    },
  },
  computed: {
    IconName() {
      return `#icon-${this.name}`
    },
  },
  data() {
    return {}
  },
  methods: {},
  created() {},
  mounted() {},
}
</script>
<style lang="less" scoped>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
