<!-- 侧;边栏-tree组件 -->
<template>
  <div class="aside-page" ref="treeList">
    <div
      class="left-box-path"
      @click="addFirstNode"
      v-if="!areaData.length && (roleValue === '3' || roleValue === '4')"
    >
      &nbsp;&nbsp;&nbsp;
      <Icon class="iconfont icon-muluguize catalog-icon"></Icon>添加目录
    </div>
    <Tree
      v-if="areaData.length && (roleValue === '3' || roleValue === '4')"
      class="data-tree-list"
      children-key="children"
      :data="areaData"
      :render="renderContent"
    >
    </Tree>
    <Tree
      class="data-tree-list"
      v-if="areaData.length && (roleValue === '1' || roleValue === '2')"
      :data="areaData"
      children-key="children"
      :load-data="loadData"
      :render="renderContent"
    ></Tree>
    <div class="tree-menu" :style="{ ...menuPosition }" v-show="showMenu">
      <ul class="tree-menu-ul">
        <li class="tree-menu-li" @click="handleRename">重命名</li>
        <li class="tree-menu-li" @click="addBrotherNode">添加同级目录</li>
        <li class="tree-menu-li" @click="addChildNode">添加下级目录</li>
        <li class="tree-menu-li" @click="removeThisNode">删除</li>
      </ul>
      <div class="menu-arrow"></div>
    </div>
  </div>
</template>

<script>
import { roleMixins } from '_mixins'
import { mapApi } from '_utils'
export default {
  name: 'AsideTree',

  components: {},
  mixins: [roleMixins],
  props: {
    title: String,
    areaData: {
      required: true,
      type: Array,
    },
    activedNode: {
      required: true,
      type: String,
      default: '',
    },
  },

  data() {
    return {
      addShow: false,
      editBtn: false,
      data1: [],
      action: false,
      menuShowNode: null, // 显示menu的node id
      renameNode: null, // 重命名状态node的 id
      parentNode: {},

      showMenu: false,
      menuNode: null,
      menuData: null,
      menuRoot: null,
      menuPosition: {
        left: 0,
        righ: 0,
      },
    }
  },

  computed: {},

  watch: {
    activedNode(now) {
      if (!now) {
      }
    },
  },

  created() {},

  mounted() {
    document.addEventListener('click', e => {
      this.showMenu = false
      this.action = false
    })
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    // 离开该页面需要移除这个监听的事件，不然会报错
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    //
    handleScroll() {},
    ...mapApi(['getGroupTreeBySchoolId']),
    // 动态加载
    loadData(item, callback) {
      this.getGroupTreeBySchoolId({
        schoolId: item.id,
      }).then(res => {
        let data = []
        if (res.length) data = this.recursiveFunction(res)
        else data = []
        callback(data)
      })
    },
    // 遍历方法
    recursiveFunction(data) {
      const getStr = function(list, arrNew, path) {
        list.forEach((row, index) => {
          arrNew[index].title = row.name
          if (!path) {
            arrNew[index].path = row.name
          } else {
            arrNew[index].path = `${path}-${row.name}`
          }
          arrNew[index].expand = false
          arrNew[index].children = getStr(
            row.children,
            arrNew[index].children,
            arrNew[index].path
          )
        })
        return arrNew
      }
      return getStr(data, data, data.catalogName)
    },
    renderContent(h, { root, node, data }) {
      if (this.roleValue === '3' || this.roleValue === '4')
        return (
          <div class="tree-item">
            <div
              class={this._renderTreeNodeClass(node, data)}
              onClick={e => this.handleNodeClick(node, data, e)}
            >
              <div class="tree-node-tree">
                {this._renderNodeContent(h, node, data, this.renameNode, root)}
              </div>

              <div
                class="tree-menu-btn"
                onClick={e => {
                  e.stopPropagation()
                  this.handleMenuClick(e, root, node, data)
                }}
              >
                <i class="iconfont icon-gengduocaozuo"></i>
              </div>
            </div>
            {/* this._renderMenuContent(root, node, data) */}
          </div>
        )
      else if (data.title)
        return (
          <div class="tree-item">
            <div
              class={this._renderTreeNodeClass(node, data)}
              onClick={() => this.handleNodeClick(node, data)}
            >
              {this._renderNodeContent(h, node, data, this.renameNode, root)}
            </div>
            {/* this._renderMenuContent(root, node, data) */}
          </div>
        )
    },
    /**
     * * Dom Events
     */
    handleNodeClick(node, data, e) {
      if (this.action) {
        return
      }
      //  	e.stopPropagation()
      //    if (hasChildren) {
      //      data.expand = !data.expand
      //    } else {
      //      this.activedNode = data.id
      this.$emit('on-click', data)
      //    }
    },
    // menu点击事件
    handleMenuClick(e, root, node, data) {
      this.menuPosition = {
        left: `${e.clientX + 30}px`,
        top: `${e.clientY - 80}px`,
      }
      e.stopPropagation()
      this.menuNode = node
      this.menuData = data
      this.menuRoot = root
      this.showMenu = true

      if (this.action) {
        return
      }

      if (this.menuShowNode === data.id) {
        this.menuShowNode = null
      }
      //
      else {
        this.menuShowNode = data.id
      }
      if (this.activedNode !== data.id) {
        this.$emit('on-click', data)
      }
    },

    /**
     * * Dom Events
     * * 菜单操作
     */
    // 重命名
    handleRename(e, data = this.menuData) {
      // e.stopPropagation()
      this._closeMenu()
      this.editBtn = true
      this.action = true
      this.renameNode = data.id
      // this.$emit('on-renameFuc', 'rename', data)
    },
    // 添加子节点
    addChildNode(node = this.menuNode, data = this.menuData) {
      if (!this.addShow) {
        this.action = true
        this.editBtn = false
        this.addShow = true
        const children = data.children || []
        const id = 'temp' + Math.random * 1000
        children.push({
          id,
          title: '',
          level: data.level + 1,
          parent: data.id,
        })
        this.$set(data, 'children', children)
        this.renameNode = id
        data.expand = true
      }
    },
    addFirstNode() {
      if (!this.addShow) {
        this.action = true
        this.editBtn = false
        this.addShow = true
        const id = 'temp' + Math.random * 1000
        this.areaData.push({
          level: 0,
          id,
          title: '',
          parent: '',
        })
        this.renameNode = id
      }
    },
    // 添加同级结点
    addBrotherNode(
      e,
      root = this.menuRoot,
      node = this.menuNode,
      data = this.menuData
    ) {
      if (!this.addShow) {
        this.action = 1
        this.addShow = true
        const parentKey = root.find(el => el === node).parent
        this.editBtn = false
        var parent = {}
        const str = root.find(el => el.nodeKey === parentKey)
        if (!str) {
          parent = this.areaData
        } else {
          parent = root.find(el => el.nodeKey === parentKey).node
        }

        const children = parent.children || []
        const id = 'temp' + Math.random() * 1000
        if (!str) {
          this.areaData.push({
            level: data.level,
            id,
            title: '',
            parent: '',
          })
        } else {
          children.push({
            id,
            title: '',
            level: data.level,
            parent: data.parentId,
          })
          this.$set(parent, 'children', children)
        }
        this.renameNode = id
      }
    },

    // 删除该节点
    removeThisNode(
      root = this.menuRoot,
      node = this.menuNode,
      data = this.menuData
    ) {
      this.$emit('on-delete', data)
      //    parent.catalogs.splice(index, 1)
    },

    // 重命名 完成
    handleRenameConfirm(title, data) {
      //    console.log(title, data)
      if (!title) {
        this.$alertError('目录名称不能为空')
        return
      }
      data.catalogName = title
      this.$emit('on-edit', data, this.editBtn)
      this.editBtn = true
      this.action = false
      this._clearRenameStatus()
    },

    /**
     * * Ajax Request
     */
    /**
     * * Custom Methods
     */
    // 渲染结点内容
    _renderNodeContent(h, node, data, renameId, root) {
      const title = data.title

      if (renameId === data.id)
        return (
          <div class="node-edit" onClick={e => e.stopPropagation()}>
            <Input
              v-model={data.title}
              placeholder="目录名称，最长12字"
              maxlength="12"
            />
            <i
              class="iconfont icon-baocun"
              onClick={() => this.handleRenameConfirm(title, data)}
            ></i>
            <i
              class="iconfont icon-guanbi"
              onClick={() => {
                this._clearRenameStatus(node, data, renameId, root)
              }}
            ></i>
          </div>
        )
      else {
        if (data.title)
          if (data.title.length > 14)
            return h(
              'Tooltip',
              {
                style: {
                  display: 'inline-block',
                  width: '100%',
                },
                props: {
                  content: data.title,
                  placement: 'top',
                },
              },
              [h('span', data.title)]
            )
          else return h('span', data.title)
      }
    },
    // 渲染菜单按钮
    _renderMenuIcon(node) {
      if (node.children) return ''
      else return <div class="tree-menu-btn">123</div>
    },
    // 渲染结点class
    _renderTreeNodeClass(node, data) {
      const hasChildren = !!node.children
      let cla = 'tree-node'
      if (this.activedNode === data.id) cla += ' node-actived'
      if (!hasChildren) cla += ' last-node'
      return cla
    },
    // 渲染菜单内容
    _renderMenuContent(root, node, data) {
      const menuShowNode = this.menuShowNode
      if (menuShowNode !== data.id) return ''
      return (
        <div class="tree-menu">
          <ul class="tree-menu-ul">
            <li class="tree-menu-li" onClick={e => this.handleRename(e, data)}>
              重命名
            </li>
            <li
              class="tree-menu-li"
              onClick={() => this.addBrotherNode(root, node, data)}
            >
              添加同级目录
            </li>
            <li
              class="tree-menu-li"
              onClick={() => this.addChildNode(node, data)}
            >
              添加下级目录
            </li>
            <li
              class="tree-menu-li"
              onClick={() => this.removeThisNode(root, node, data)}
            >
              删除
            </li>
          </ul>
        </div>
      )
    },

    // 清除状态
    _closeMenu() {
      this.menuShowNode = null
    },
    _clearRenameStatus(node, data, id, root) {
      this.action = false
      this.addShow = false
      if (this.editBtn) this.renameNode = null
      else {
        var parentKey1 = root.find(el => el === node).parent
        var str1 = root.find(el => el.nodeKey === parentKey1)
        if (!str1) {
          this.areaData.splice(this.areaData.length - 1, 1)
        } else {
          const delKey = root.find(el => el === node).parent
          const parentDel = root.find(el => el.nodeKey === delKey).node
          const index = parentDel.children.indexOf(data)
          parentDel.children.splice(index, 1)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.aside-page {
  // width: 300px;
  padding-left: 10px;
  padding-bottom: 100px;
  height: 75%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  & /deep/ ul,
  & /deep/ li {
    list-style: none;
    margin: 0 !important;
    /*margin-top: -6px !important;*/
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    /*color: @font-color-1;*/
    padding: 0 20px;
  }
  .tree-list {
    padding: 0 20px;
    flex: 1;

    overflow: auto;
  }
  .tree {
    margin-top: 0px;
  }

  .left-box-path {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(22, 139, 245, 1);
    line-height: 22px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .data-tree-list {
    & /deep/ .ivu-tree-arrow {
      z-index: 2;
      position: relative;
    }

    & /deep/ .tree-item {
      display: inline-block;
      width: 97%;
      height: 32px;
      position: relative;
    }
    & /deep/ .tree-node {
      width: 100%;
      height: 100%;
      line-height: 32px;
      margin-left: 10px;
      position: relative;
      z-index: 1;
      cursor: pointer;
      padding-right: 12px;
      float: left;
      font-weight: 500;
      color: #000000d9; //TODO 设备管理 设备分组 未选中字体颜色
      .tree-node-tree {
        float: left;
        width: 50%;
      }
      .ivu-tooltip-inner {
        max-width: 300px;
        padding: 6px 2px;
      }
      span {
        display: block;
        width: 160px;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .tree-menu-btn {
        float: right;
        width: 20px;
        display: none;
      }

      &::before {
        content: '';
        display: block;
        width: 100vw;
        height: 32px;
        position: absolute;
        left: -50vw;
        top: 0;
        z-index: -1;
      }
      &:hover,
      &.node-actived {
        color: rgba(22, 139, 245, 1);
        .tree-menu-btn {
          display: block;
        }
        &::before {
          background: rgba(217, 234, 255, 1);
        }
      }
    }

    & /deep/ .node-edit {
      display: inline-block;
      .ivu-input-wrapper {
        width: auto;
        .ivu-input {
          width: 150px;
          height: 32px;
        }
      }
      .iconfont {
        margin-left: 10px;
        font-size: 14px;
      }
    }
    & /deep/ .tree-node:hover,
    .tree-node.node-actived {
      background: #232f4b;
      color: #0f8dff;
    }
  }

  .tree-menu {
    position: fixed;
    right: 70px;
    top: 32px;
    width: 160px;
    height: 128px; //TODO 设备管理 侧边栏 操作栏 高度
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 0px rgba(1, 69, 173, 0.17);
    z-index: 9999;
    margin-top: 11px;
    background: #ffffff; //TODO 设备管理 设备分组 选择框 点击按钮后 出现的功能栏背景颜色
    .tree-menu-ul {
      list-style: none;
      font-size: 14px;
      padding: 0;
      // margin-top: 14px !important;
      .tree-menu-li {
        line-height: 32px;
        width: 100%;
        padding-left: 10px;
        cursor: pointer;
        &:hover {
          background: #e3f4fc; //TODO 设备管理 设备分组 选择框 点击按钮后 出现的功能栏选择框背景颜色
          color: #0f8dff; //TODO 设备管理 设备分组 选择框 点击按钮后 出现的功能栏选择框字体颜色
          /*color: @color-primary;*/
        }
      }
    }

    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border: 11px solid transparent;
      /*border-bottom-color: @bg-white;*/

      position: absolute;
      top: -22px;
      left: 0;
      right: 0;
      margin: auto;
    }
    .menu-arrow {
      position: absolute;
      top: 41%;
      left: -12%;
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      border-color: transparent #ffffff transparent transparent; //TODO 设备管理 设备分组功能框点击后 出现的选择功能框三角颜色
    }
  }
  & /deep/ .data-tree-list {
    .ivu-tree-title {
      height: 32px;
      line-height: 32px;

      &:hover {
        // background: #94979c;  TODO 去掉信发管理选择时 闪烁颜色
        color: #0f8dff;
      }
    }
    .ivu-tree-arrow {
      z-index: 8;
      .ivu-icon-ios-arrow-forward {
        top: -5px;
      }
    }
    .ivu-tree-title-selected {
      background-color: transparent;
      display: inline-block;
      position: relative;
      z-index: 3;
      overflow: visible;
      color: #0f8dff;
      &::before {
        content: '';
        display: block;
        width: 100vw;
        height: 32px;

        /*background: rgba(217, 234, 255, 1);*/

        position: absolute;
        left: -50vw;
        top: 0;

        z-index: -1;
      }
    }
    .tree-node:hover,
    .tree-node.node-actived {
      background: #232f4b;
      color: #0f8dff; //TODO 设备管理 设备分组选择框字体颜色
    }
    .tree-node:hover::before,
    .tree-node.node-actived::before {
      background: #e3f4fc; //TODO 设备管理 设备分组选择框背景颜色
      color: #000000d9;
    }
  }
  & /deep/ .data-tree-list .tree-node:hover,
  .data-tree-list .tree-node.node-actived {
    background: #232f4b;
    color: #0f8dff;
  }
}

.aside-page::-webkit-scrollbar {
  width: 6px;
  background-color: #ffff;
}
.aside-page::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(62, 77, 108, 1);
  border-radius: 8px;
  background: rgba(62, 77, 108, 1);
  border-radius: 8px;
}
.aside-page::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.3);
}
</style>
