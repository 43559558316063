import { mapState } from 'vuex'

/**
 * * 全局混入(作用于每一个组件)
 */
export const globalMixins = { created() {} }

/**
 * * 用户角色
 */
export const roleMixins = {
  computed: {
    ...mapState({
      roleValue: state => state?.loginData?.userInfo?.userRole,
    }),
    // 1超级管理员、2区域管理员、3学校管理员、4设备管理员
    isSuperAdmin() {
      return this.roleValue === '1'
    },
    isAreaAdmin() {
      return this.roleValue === '2'
    },
    isSchoolAdmin() {
      return this.roleValue === '3'
    },
    isEquipmentAdmin() {
      return this.roleValue === '4'
    },
    AdminSA() {
      return this.roleValue === '1' || this.roleValue === '2'
    },
    AdminSE() {
      return this.roleValue === '3' || this.roleValue === '4'
    },
    isClassAdmin() {
      return this.roleValue === '5'
    },
    isClassVistor() {
      return this.roleValue === '5'
    },
  },
}
