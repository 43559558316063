<!-- excel文件导入组件 -->
<template>
  <Upload action="//" :before-upload="handleUpload">
    <slot>
      <Button icon="ios-cloud-upload-outline" :loading="loadingStatus">
        表格导入
      </Button>
    </slot>
  </Upload>
</template>

<script>
import Excel from 'exceljs'

export default {
  name: 'ExcelParser',

  props: {
    // 标题字段
    header: {
      required: true,
      type: Array,
    },
    // 支持的文件格式
    format: {
      type: Array,
      default: () => ['xls', 'xlsx'],
    },
  },

  data() {
    return {
      loadingStatus: false,
      workbook: null, // 工作簿，workbook
    }
  },

  methods: {
    // 处理文件上传
    handleUpload(file) {
      if (!this.validateFormat(file.name)) return false // 验证格式

      // 初始化状态
      this.loadingStatus = true
      this.$emit('start-import')

      // 读取文件
      const reader = new FileReader()
      reader.addEventListener('load', this.processExcel)
      reader.readAsArrayBuffer(file)

      return false
    },

    processExcel(e) {
      this.workbook = new Excel.Workbook()
      const wb = this.workbook
      const json = {}
      wb.xlsx.load(e.target.result).then(() => {
        wb.eachSheet((sheet, sheetId) => {
          const sheetData = []
          sheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            if (rowNumber > 2) {
              sheetData.push(this.assignArray(this.header, row.values.slice(1)))
            }
          })
          json[sheet.name] = sheetData
        })

        this.loadingStatus = false

        // 过滤空数据
        for (const key of Object.keys(json)) {
          json[key] = json[key].filter(d => Object.values(d).some(i => i))
        }
        this.$emit('finish-import', json)
      })
    },

    // 验证格式
    validateFormat(name) {
      const ext = name.split('.')[1]
      if (ext && this.format.includes(ext)) return true
      else {
        this.$alertError(
          '文件格式错误',
          `${name}文件格式错误，只支持${this.format.join(',')}！`
        )
        return false
      }
    },

    // keys&values => object
    assignArray(keys, values) {
      const result = {}
      keys.map((key, index) => (result[key] = values[index]))
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.ivu-upload {
  display: inline-block;

  & /deep/ .ivu-upload-list {
    display: none;
  }
}
</style>
