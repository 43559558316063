<template>
  <div class="tree-transfer">
    <tree-transfer
      ref="treeTransfer"
      :title="title"
      :from_data="fromData"
      :to_data="toData"
      :defaultProps="defaultProps"
      @addBtn="add"
      @removeBtn="remove"
      @left-check-change="leftCheckChange"
      @right-check-change="rightCheckChange"
      :mode="mode"
      height="581px"
      placeholder="请输入搜索内容"
      :high-light="true"
      filter
      openAll
    >
      <div slot="title-left" class="title-slot">{{ fromSelected }}</div>
      <div slot="title-right" class="title-slot">{{ toSelected }}</div>
      <div slot="from" class="search-slot">
        <!--        <i class="iconfont icon-sousuo"></i>-->
      </div>
      <div slot="to" class="search-slot">
        <!--        <i class="iconfont icon-sousuo"></i>-->
      </div>
    </tree-transfer>
  </div>
</template>

<script>
import treeTransfer from 'el-tree-transfer' // 引入
export default {
  name: 'TreeTransfer',
  components: {
    treeTransfer,
  },
  data() {
    return {
      data: [],
      ids: [],
      title: ['未选设备', '已选设备'],
      mode: 'transfer', // transfer addressList
      defaultProps: {
        label: 'label',
        children: 'children',
      },
      // fromData: [
      //   {
      //     id: '1',
      //     pid: 0,
      //     label: '一级 1',
      //     children: [
      //       {
      //         id: '1-1',
      //         pid: '1',
      //         label: '二级 1-1',
      //         disabled: true,
      //         children: [],
      //       },
      //       {
      //         id: '1-2',
      //         pid: '1',
      //         label: '二级 1-2',
      //         children: [
      //           {
      //             id: '1-2-1',
      //             pid: '1-2',
      //             children: [],
      //             label: '二级 1-2-1',
      //           },
      //           {
      //             id: '1-2-2',
      //             pid: '1-2',
      //             children: [],
      //             label: '二级 1-2-2',
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ],
      toData: [],
    }
  },
  props: {
    fromData: Array,
    fromSelected: Number,
    toSelected: Number,
  },
  methods: {
    add(fromData, toData, obj) {
      // this.fromData = fromData
      this.toData = toData
      this.$emit('on-change', fromData, toData)
    },
    // 监听穿梭框组件移除
    remove(fromData, toData, obj) {
      console.log(fromData, toData, obj, 'jjjjj')
      // this.fromData = fromData
      this.toData = toData
      this.$emit('on-change', fromData, toData)
    },
    leftCheckChange(nodeObj, treeObj, checkAll) {
      if (checkAll) {
        let renderData = _.cloneDeep(this.fromData)
        renderData = this.renderCheckedTree(renderData)
        this.$refs.treeTransfer.$refs['from-tree'].from_check_keys = renderData
        this.$refs.treeTransfer.$refs['from-tree'].setCheckedNodes(renderData)
      }
    },
    rightCheckChange(nodeObj, treeObj, checkAll) {
      if (checkAll) {
        let renderData = _.cloneDeep(this.toData)
        renderData = this.renderCheckedTree(renderData)
        this.$refs.treeTransfer.$refs['to-tree'].to_check_keys = renderData
        this.$refs.treeTransfer.$refs['to-tree'].setCheckedNodes(renderData)
      }
    },
    renderCheckedTree(data) {
      const renderData = data.filter(item => {
        return !item.disabled
      })
      renderData.forEach(item => {
        if (item.children && item.children.length > 0) {
          item.children = this.renderCheckedTree(item.children)
        }
      })
      return renderData
    },
  },
  mounted() {},
}
</script>

<style scoped lang="less">
.tree-transfer {
  &-left {
    width: 320px;
    height: 581px;
    background: #232f4b;
    border-radius: 4px;
    /*border: 1px solid rgba(237, 235, 235, 1);*/
  }

  &-center {
    width: 126px;
  }

  &-right {
    width: 320px;
    height: 581px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    border: 1px solid rgba(237, 235, 235, 1);
  }
  /deep/.transfer-title {
    border-bottom: none;
    display: flex;
    background-color: #fff;
    justify-content: space-between;
  }
  /deep/.transfer-left,
  .transfer-right {
    height: 100% !important;
  }
  /deep/.el-input__inner {
    width: 280px;
    height: 40px;
    background: rgba(255, 255, 255, 1);
    border-radius: 23px;
    border: 1px solid rgba(181, 210, 255, 1);
  }
  /deep/.el-input {
    text-align: center;
  }
  /deep/.el-button.is-circle {
    width: 40px;
    height: 32px;
    padding: 0;
    border-radius: 4px;
  }
  .title-slot {
    position: absolute;
    top: 0;
    right: 100px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #0f8dff;
  }
  .search-slot {
    position: absolute;
    right: 35px;
    top: 60px;
    z-index: 9;
    color: #0f8dff;
  }
}
/deep/.transfer-left {
  background: #ffffff; //TODO 应用下发 设备选择部分 左边功能框背景颜色
  box-shadow: 0px 2px 20px 0px #d2d5de; //TODO 应用下发 设备选择部分 左边功能框阴影颜色
  border: none !important;
}
/deep/.transfer-title {
  color: #000000d9 !important; //TODO 应用库 下发 设备选择 左边框 ”未选设备“字体颜色
  background-color: transparent !important;
}
/deep/.transfer-right {
  background: #ffffff; //TODO 应用下发 设备选择部分 右边功能框背景颜色
  border: none !important;
  box-shadow: 3px 6px 24px 0px rgba(3, 11, 36, 0.5); //TODO 应用下发 设备选择部分 右边功能框阴影颜色
}

/deep/.el-tree-node__content {
  &:hover {
    background: #e3f4fc !important; //TODO 应用下发 设备选择部分 左边功能框中选择框背景颜色
  }
}
/deep/.el-button.is-circle {
  color: #fff;
}
/deep/.el-input__inner {
  background: #ffffff !important; //TODO 应用库 下发 设备选择 左边功能框 搜索框背景颜色
  border: none !important;
}
/deep/ .el-checkbox__inner {
  border: 1px solid #ccc;
}
/deep/ .el-checkbox__input.is-disabled .el-checkbox__inner {
  background-color: #ffffff; //TODO 应用库 下发 设备选择 左边框 复选框（小方框）背景颜色
  border: 1px solid #ccc;
}
/deep/.el-tree {
  background: transparent !important;
}
/deep/.transfer-left,
/deep/.transfer-right {
  /*border: 1px solid rgba(63, 83, 130, 1) !important;*/
}
/deep/.el-tree-node__label {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000d9;
}
/deep/.el-tree__empty-text {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(210, 213, 222, 1);
}
/deep/.el-checkbox__inner {
  border: 1px solid #d2d5de;
}
/deep/.el-input--small {
  input::-webkit-input-placeholder {
    color: rgba(166, 171, 189, 1) !important;
  }
}
/deep/.el-button--primary.is-disabled,
/deep/.el-button--primary.is-disabled:active,
/deep/.el-button--primary.is-disabled:focus,
/deep/.el-button--primary.is-disabled:hover {
  //color: #0F8DFF;
  background-color: #d2d5de; //TODO 下发管理 未选择元素时 传送按钮的属性

  i {
    color: #fff !important;
    font-weight: 600;
  }
}
/deep/.el-button.is-circle {
  i {
    color: #fff;
    font-weight: 600;
  }
}
/deep/.transfer-main {
  //滚动条整体部分，可以设置宽度啥的
  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }

  //滚动条两端的按钮
  &::-webkit-scrollbar-button {
    display: none;
  }

  //外层轨道
  &::-webkit-scrollbar-track {
  }

  //内层滚动槽
  &::-webkit-scrollbar-track-piece {
  }

  //滚动的滑块
  &::-webkit-scrollbar-thumb {
    width: 6px;
    height: 49px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 3px;
  }

  //边角
  &::-webkit-scrollbar-corner {
  }

  //定义右下角拖动块的样式
  &::-webkit-resizer {
  }
}
</style>
