export default {
  path: '/classcard',
  redirect: '/classcard/statistic',
  component: () => import('_views/classCard/index'),
  children: [
    {
      path: 'statistic',
      meta: {
        auth: true,
        title: '班牌统计',
        roles: ['CLASSADMIN', 'CLASSVISTOR'],
      },
      component: () => import('_views/classCard/statistic/index'),
    },
    {
      path: 'school',
      meta: {
        auth: true,
        title: '学校管理',
        roles: ['CLASSADMIN'],
      },
      component: () => import('_views/classCard/school/index'),
    },
    {
      path: 'account',
      meta: {
        auth: true,
        title: '账号管理',
        roles: ['CLASSADMIN'],
      },
      component: () => import('_views/classCard/account/index'),
    },
    {
      path: 'detail',
      meta: {
        auth: true,
        title: '数据概览详情',
        roles: ['CLASSADMIN', 'CLASSVISTOR'],
      },
      component: () => import('_views/classCard/detail/index'),
    },
  ],
}
