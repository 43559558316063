export default {
  path: '/school',
  component: () => import('_views/school/index'),
  children: [
    {
      path: '',
      name: 'SchoolIndex',
      meta: {
        auth: true,
        title: '学校管理',
        roles: ['ADMIN', 'AREA'],
      },
      component: () => import('_views/school/SchoolIndex/index'),
    },
  ],
}
