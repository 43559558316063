/**
 * * 自定义工具插件
 * * 以下工具与业务耦合，使用频率远高于tools
 * * 组件内可直接通过this访问
 */
import ajax from '_library/ajax'
import AlertConfirm from '_components/common/AlertConfirm'
import AssetsViewer from '_components/common/AssetsViewer'

/**
 * * 工具函数
 */

const basePlugin = {}

basePlugin.install = function(Vue, options) {
  /**
   * @description 用于在template中输出数据
   * @param {content} 需要输出的内容
   */
  Vue.prototype.$log = window.console.log

  /**
   * @description 操作成功提示框
   * @author fanzhongxu
   * @param {*} content 提示内容
   */
  Vue.prototype.$alertSuccess = function(content) {
    this.$Message.config({
      top: 70,
      duration: 3,
    })
    this.$Message.success(content || '操作成功')
  }

  /**
   * @description 操作失败提示框
   * @author fanzhongxu
   * @param {*} content 提示内容
   */
  Vue.prototype.$alertError = function(content) {
    this.$Message.config({
      top: 70,
      duration: 3,
    })
    this.$Message.error(content || '操作失败')
  }

  /**
   * @description 操作确认提示框
   * @author fanzhongxu
   * @param {*} {
   *     content, 标题
   *     okText,  确认按钮文字提示
   *     cancelText,  取消按钮文字提示
   *     onConfirm, 确认回调
   *     onCancel,  取消回调
   *   }
   */
  Vue.prototype.$alertConfirm = function({
    content,
    okText,
    cancelText,
    onConfirm,
    onCancel,
  }) {
    new AlertConfirm().show({
      content,
      okText,
      cancelText,
      onConfirm,
      onCancel,
    })
  }

  /**
   * @description 资源预览
   * @param {*} { type, src } type资源类型(audio | img | video), src为资源完整的路径
   * @returns 弹窗实例，调用 hide() 方法隐藏
   */
  Vue.prototype.$assetsViewer = function({ type, src }) {
    const instance = new AssetsViewer()
    instance.show({ type, src })
    return instance
  }

  // * 注册ajax工具
  Object.keys(ajax).forEach(key => (Vue.prototype[key] = ajax[key]))
}

export default basePlugin
