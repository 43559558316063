export default {
  path: '/account',
  component: () => import('_views/account/index'),
  children: [
    {
      path: '',
      name: 'AccountIndex',
      meta: {
        auth: true,
        title: '账号管理',
        roles: ['ADMIN', 'AREA', 'SCHOOL'],
      },
      component: () => import('_views/account/AccountIndex/index'),
    },
    {
      path: 'DeviceIndex',
      name: 'DeviceIndex',
      meta: {
        auth: true,
        title: '设备分配',
        roles: ['ADMIN', 'AREA', 'SCHOOL'],
      },
      component: () => import('_views/account/DeviceIndex/index'),
    },
    {
      path: 'addDeviceIndex',
      name: 'addDeviceIndex',
      meta: {
        auth: true,
        title: '添加设备',
        roles: ['ADMIN', 'AREA', 'SCHOOL'],
      },
      component: () => import('_views/account/DeviceIndex/addDeviceIndex'),
    },
  ],
}
