// * pager组件
<template>
  <Page class="pager-wrapper" v-bind="$attrs" v-on="$listeners" show-sizer />
</template>

<script>
export default {
  inheritAttrs: false,

  name: 'Pager',

  components: {},

  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  created() {},

  mounted() {},

  methods: {},
}
</script>

<style lang="scss">
.pager-wrapper {
  .ivu-page-item {
    border-radius: 50%;
    background: #ffffff; //TODO 设备管理 右下方页码 各页码 背景颜色
    border-color: transparent;
    a {
      color: #606266; //TODO 设备管理 右下方 页码数字 字体颜色
    }

    &.ivu-page-item-active {
      background: #0f8dff; //TODO 页码选中背景颜色
      a {
        color: #ffffff;
      }
    }
    &:not(.ivu-page-item-active):hover {
      color: #0f8dff;
      opacity: 0.8;
      //TODO 设备管理 右下方页码部分 当鼠标点击某一页码时 页码 字体颜色
      a {
        color: #0f8dff;
        opacity: 0.8;
      }
    }
  }

  .ivu-page-prev,
  .ivu-page-next {
    border-radius: 50%;
    background: #ffffff; //TODO 设备管理 右下方 页码按钮中 上一页按钮背景颜色
    border-color: transparent;

    a {
      color: rgba(166, 171, 189, 1);
    }
  }

  .ivu-page-options-sizer {
    .ivu-select-selection {
      min-width: 100px;
      height: 32px;
      // background: rgba(35, 47, 75, 1);
      border-radius: 1px !important; //TODO 策略管理 策略库下拉框圆角设置
      border-color: transparent;

      color: rgba(166, 171, 189, 1);
    }
    .ivu-select-selected-value {
      height: 30px;
      line-height: 30px;
    }
  }
}
</style>
