import AlertConfirmCom from './index.vue'
import Vue from 'vue'

class AlertConfirm {
  constructor() {
    const Instance = Vue.extend({
      name: 'AlertConfirm',
      data() {
        return {
          content: '是否确认此操作？',
          okText: '确认',
          cancelText: '取消',
          onConfirm: () => {},
          onCancel: () => {},
          isShow: false,
        }
      },
      render(h) {
        return h(AlertConfirmCom, { props: this.$data })
      },
    })

    this.instance = new Instance()
    this.component = this.instance.$mount()
    this.dom = this.component.$el
    document.body.appendChild(this.dom)
  }

  show(props) {
    const { onConfirm: confirm, onCancel: cancel, ...others } = props

    Object.assign(this.instance.$data, {
      onConfirm: () => {
        confirm && confirm()
        this._hide()
      },
      onCancel: () => {
        cancel && cancel()
        this._hide()
      },
      ...others,
      isShow: true,
    })
  }

  _hide() {
    this.instance.$data.isShow = false
    this.instance.$nextTick(() => {})
    setTimeout(() => {
      this.instance.$destroy()
      document.body.removeChild(this.dom)
    }, 100)
  }
}

export default AlertConfirm
