import { mapApi } from '_utils'
import Equipment from '_entities/equipment'
import config from '_config'
import axios from 'axios'

const screenshotURL = config.screenshotURL
let intranet = 0
const {
  getEquipmentsByIds,
  getScreenShot,
  getSendOrder,
  getEquipmentById,
} = mapApi([
  'getEquipmentsByIds',
  'getScreenShot',
  'getSendOrder',
  'getEquipmentById',
])
export default {
  namespaced: true,
  state: {
    equipments: [],
    equipmentsByPage: [],
    curPageIndex: 0, // 列表模式 页索引
    curIndex: 0, // 大图模式 索引
    checkedEq: [], // 列表模式 选中的数据
    first: true, // true:进入巡课界面  false:在巡课界面
    mode: 0, // 模式 0：列表模式  1：大图模式
  },
  getters: {
    isModeList(state) {
      return state.mode === 0
    },
    selectedEquipments(state) {
      return state.equipments.filter(e => state.checkedEq.includes(e.id))
    },
  },
  mutations: {
    handleEquipments(state, data) {
      state.first = true
      state.equipments = data.map(e => new Equipment(e))
      // console.log('🚀 ~ handleEquipments ~ state.equipments:', state.equipments)
    },

    setCurPageIndex(state, index) {
      console.log('setCurPageIndex ')
      state.curPageIndex = index
    },

    setCurIndex(state, index) {
      console.log('setCurIndex ')
      state.curIndex = index
    },

    setCheckedEq(state, ids) {
      console.log('setCheckedEq ')
      state.checkedEq = state.equipments.filter(e => ids.includes(e.id))
    },

    toggleModeList(state) {
      console.log('toggleModeList ')
      state.mode = 0
      state.checkedEq = []
    },
    toggleModeScreen(state) {
      state.mode = 1
    },
    setFirst(state) {
      state.first = false
    },
    updateCover(state, start) {
      console.log('updateCover ')
      state.equipments.slice(start, start + 8).forEach(e => {
        e.cover = `${screenshotURL}/${e._key}.jpg?_t=${+new Date()}`
      })
    },
    setStream(state, { id, streamUrl, intranet }) {
      console.log('setStream ')
      state.equipments = state.equipments.map(e => {
        if (e.id === id) {
          e.streamUrl = streamUrl
          e.intranet = intranet
        } else e.streamUrl = ''
        return e
      })
    },
    delStream(state, targetEq) {
      state.equipments = state.equipments.map(e => {
        if (e.id === targetEq.id) {
          e.streamUrl = ''
        }
        return e
      })
    },
  },
  actions: {
    async setEquipmentsByQids({ commit, state }, ids) {
      console.log('setEquipmentsByQids ')
      const data = await getEquipmentsByIds({ eqIds: ids.join(',') })
      // 封装对象
      commit('handleEquipments', data)
      return data
    },

    async fetchScreenshotByIds({ commit, state }) {
      console.log('fetchScreenshotByIds ')
      const start = state.curPageIndex * 8
      const ids = state.equipments.slice(start, start + 8).map(e => e.id)
      getScreenShot({ ids }).then(res => {})
      //  update cover after 2 seconds
      setTimeout(() => {
        commit('updateCover', start)
      }, 2000)
    },

    async fetchStream({ commit, state }, eq) {
      const id = eq.id
      console.log('接口方法' + eq.localIp + 'id:' + eq.id)
      // console.log('🚀 ~ fetchStream ~ eq:', eq)
      if (eq.localIp != null && eq.dnsApi != null) {
        console.log('进入!') // 推流方法
        axios.defaults.baseURL = eq.dnsApi
        await axios({
          url: '',
          params: {},
          timeout: 1500,
        }).then(
          response => {
            console.log('连接成功!')
            // console.log('🚀🚀 ~ dnsApi 连接成功! ~')
            intranet = 1
            return true
          },
          error => {
            console.log('错误', error.message)
            // console.log('🚀 ~ 错误 ~ ', error.message)
            intranet = 0
            return false
          }
        )
      }
      console.log(intranet)
      // console.log('🚀 ~ fetchStream ~ intranet:', intranet)
      // 根据 id 获取设备的集控版本 version
      const { version } = await getEquipmentById({ id })
      const streamUrl = await getSendOrder({ //eslint-disable-line
        eqIds: [eq.id],
        orderCode: 'startPush',
        isIntranet: intranet,
        localIp: eq.localIp,
        version,
      })
      commit('setStream', { id, streamUrl, intranet })
      intranet = 0
    },

    async delEqStream({ commit }, eq) {
      commit('delStream', eq)
    },
  },
}
