/**
 * * 生成uuid
 * @author fanzhongxu
 */

import uuid from 'uuid/v4'

/**
 * @description 生成uuid（Universally Unique Identifier）
 * @author fanzhongxu
 * @returns 返回uuid
 */
export default function genUUID() {
  return uuid()
}
