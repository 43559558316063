/**
 * * roles 表明能够访问该菜单的用户角色，缺省表示全部角色都可访问
 * * url为路径
 * * 若icon不为空，则会显示图标
 */

/**
 * * ADMIN: 超级管理员
 * * AREA: 区域管理员
 * * SCHOOL: 学校管理员
 * * EQUIPMENT: 设备管理员
 * * CLASSAUTH: 班牌管理员
 * * CLASSVISTOR: 班牌游客
 */

export default [
  {
    title: '首页',
    key: 'home',
    url: '/home',
    icon: 'iconfont icon-shouye',
    roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
  },
  {
    title: '学校管理',
    url: '/school',
    icon: 'iconfont icon-xuexiao',
    roles: ['ADMIN', 'AREA'],
  },
  {
    title: '账号管理',
    url: '/account',
    icon: 'iconfont icon-zhanghao',
    roles: ['ADMIN', 'AREA', 'SCHOOL'],
  },
  {
    title: '设备管理',
    url: '/equipment',
    icon: 'iconfont icon-shebei',
    roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
  },
  {
    title: '信发管理',
    url: '/cos',
    icon: 'iconfont icon-xinfa',
    roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
    children: [
      {
        title: '信发库',
        url: '/cos/coscon',
        roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
      },
      {
        title: '下发管理',
        url: '/cos/issued',
        roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
      },
    ],
  },
  {
    title: '策略管理',
    url: '/policy',
    icon: 'iconfont icon-celve',
    roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
    children: [
      {
        title: '策略库',
        url: '/policy/policy-library',
        roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
      },
      {
        title: '下发管理',
        url: '/policy/distribution',
        roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
      },
    ],
  },
  {
    title: '软件管理',
    url: '/software',
    icon: 'iconfont icon-ruanjian',
    roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
    children: [
      {
        title: '软件库',
        url: '/software/software-library',
        roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
      },
      {
        title: '下发管理',
        url: '/software/distribution-record',
        roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
      },
      {
        title: '广告软件配置',
        url: '/software/adware-set',
        roles: ['ADMIN'],
      },
      {
        title: '弹窗拦截设置',
        url: '/software/popup-set',
        roles: ['AREA', 'SCHOOL', 'EQUIPMENT'],
      },
    ],
  },
  {
    title: '应用管理',
    url: '/application',
    icon: 'iconfont icon-yingyong',
    roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
    children: [
      {
        title: '应用库',
        url: '/application/library',
        roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
      },
      {
        title: '下发管理',
        url: '/application/detail',
        roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
      },
    ],
  },
  {
    title: '售后服务',
    url: '/after',
    icon: 'iconfont icon-shouhou',
    roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
    children: [
      {
        title: '设备报修',
        url: '/after/repair',
        roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
      },
      {
        title: '需求反馈',
        url: '/after/feedback',
        roles: ['ADMIN', 'AREA', 'SCHOOL', 'EQUIPMENT'],
      },
    ],
  },
  {
    title: '设备配置',
    url: '/set',
    icon: 'iconfont icon-peizhi',
    roles: ['ADMIN'],
    children: [
      {
        title: '通道配置',
        url: '/set/ChannelSet',
        roles: ['ADMIN'],
      },
      {
        title: '模式配置',
        url: '/set/PatternSet',
        roles: ['ADMIN'],
      },
    ],
  },
  {
    title: '班牌管理',
    icon: 'iconfont icon-banpai',
    url: '',
    roles: ['CLASSADMIN', 'CLASSVISTOR'],
    children: [
      {
        title: '数据概览',
        url: '/classcard/statistic',
        roles: ['CLASSADMIN', 'CLASSVISTOR'],
      },
      {
        title: '学校管理',
        url: '/classcard/school',
        roles: ['CLASSADMIN'],
      },
      {
        title: '账号管理',
        url: '/classcard/account',
        roles: ['CLASSADMIN'],
      },
    ],
  },
  // {
  //  title: '通用管理',
  //  icon: 'iconfont icon-banpai',
  //  url: '/manage',
  //  roles: [
  //    'CLASSADMIN',
  //    'CLASSVISTOR',
  //    'ADMIN',
  //    'AREA',
  //    'SCHOOL',
  //    'EQUIPMENT',
  //  ],
  //  children: [
  //    {
  //      title: '卡管理',
  //      url: '/manage/AccountIndex',
  //      roles: [
  //        'CLASSADMIN',
  //        'CLASSVISTOR',
  //        'ADMIN',
  //        'AREA',
  //        'SCHOOL',
  //        'EQUIPMENT',
  //      ],
  //      children: [
  //        {
  //          title: '车辆管理',
  //          url: '/manage/AccountIndex/AccountIndexOne',
  //          roles: [
  //            'CLASSADMIN',
  //            'CLASSVISTOR',
  //            'ADMIN',
  //            'AREA',
  //            'SCHOOL',
  //            'EQUIPMENT',
  //          ],
  //        },
  //      ],
  //    },
  //    {
  //      title: '校园管理',
  //      url: '/manage/DeviceIndex',
  //      roles: [
  //        'CLASSADMIN',
  //        'CLASSVISTOR',
  //        'ADMIN',
  //        'AREA',
  //        'SCHOOL',
  //        'EQUIPMENT',
  //      ],
  //      children: [
  //        {
  //          title: '学生出入管理',
  //          url: '/manage/DeviceIndex/DeviceIndexOne',
  //          roles: [
  //            'CLASSADMIN',
  //            'CLASSVISTOR',
  //            'ADMIN',
  //            'AREA',
  //            'SCHOOL',
  //            'EQUIPMENT',
  //          ],
  //        },
  //        {
  //          title: '师生宿舍管理',
  //          url: '/manage/DeviceIndex/DeviceIndexTwo',
  //          roles: [
  //            'CLASSADMIN',
  //            'CLASSVISTOR',
  //            'ADMIN',
  //            'AREA',
  //            'SCHOOL',
  //            'EQUIPMENT',
  //          ],
  //        },
  //        {
  //          title: '教育资源管理',
  //          url: '/manage/DeviceIndex/DeviceIndexThree',
  //          roles: [
  //            'CLASSADMIN',
  //            'CLASSVISTOR',
  //            'ADMIN',
  //            'AREA',
  //            'SCHOOL',
  //            'EQUIPMENT',
  //          ],
  //        },
  //        {
  //          title: '课堂评价与分析',
  //          url: '/manage/DeviceIndex/DeviceIndexFour',
  //          roles: [
  //            'CLASSADMIN',
  //            'CLASSVISTOR',
  //            'ADMIN',
  //            'AREA',
  //            'SCHOOL',
  //            'EQUIPMENT',
  //          ],
  //        },
  //      ],
  //    },
  //    {
  //      title: '考勤管理',
  //      url: '/manage/check',
  //      roles: [
  //        'CLASSADMIN',
  //        'CLASSVISTOR',
  //        'ADMIN',
  //        'AREA',
  //        'SCHOOL',
  //        'EQUIPMENT',
  //      ],
  //      children: [
  //        {
  //          title: '学生考勤',
  //          url: '/manage/check/checkstudent',
  //          roles: [
  //            'CLASSADMIN',
  //            'CLASSVISTOR',
  //            'ADMIN',
  //            'AREA',
  //            'SCHOOL',
  //            'EQUIPMENT',
  //          ],
  //          children: [
  //            {
  //              title: '正常',
  //              url: '/manage/check/checkstudent/checkstudentone',
  //              roles: [
  //                'CLASSADMIN',
  //                'CLASSVISTOR',
  //                'ADMIN',
  //                'AREA',
  //                'SCHOOL',
  //                'EQUIPMENT',
  //              ],
  //            },
  //            {
  //              title: '缺课',
  //              url: '/manage/check/checkstudent/checkstudenttwo',
  //              roles: [
  //                'CLASSADMIN',
  //                'CLASSVISTOR',
  //                'ADMIN',
  //                'AREA',
  //                'SCHOOL',
  //                'EQUIPMENT',
  //              ],
  //            },
  //            {
  //              title: '迟到',
  //              url: '/manage/check/checkstudent/checkstudentthree',
  //              roles: [
  //                'CLASSADMIN',
  //                'CLASSVISTOR',
  //                'ADMIN',
  //                'AREA',
  //                'SCHOOL',
  //                'EQUIPMENT',
  //              ],
  //            },
  //            {
  //              title: '早退',
  //              url: '/manage/check/checkstudent/checkstudentfour',
  //              roles: [
  //                'CLASSADMIN',
  //                'CLASSVISTOR',
  //                'ADMIN',
  //                'AREA',
  //                'SCHOOL',
  //                'EQUIPMENT',
  //              ],
  //            },
  //          ],
  //        },
  //        {
  //          title: '老师考勤',
  //          url: '/manage/check/checkteacher',
  //          roles: [
  //            'CLASSADMIN',
  //            'CLASSVISTOR',
  //            'ADMIN',
  //            'AREA',
  //            'SCHOOL',
  //            'EQUIPMENT',
  //          ],
  //          children: [
  //            {
  //              title: '正常',
  //              url: '/manage/check/checkteacher/checkteacherone',
  //              roles: [
  //                'CLASSADMIN',
  //                'CLASSVISTOR',
  //                'ADMIN',
  //                'AREA',
  //                'SCHOOL',
  //                'EQUIPMENT',
  //              ],
  //            },
  //            {
  //              title: '缺课',
  //              url: '/manage/check/checkteacher/checkteachertwo',
  //              roles: [
  //                'CLASSADMIN',
  //                'CLASSVISTOR',
  //                'ADMIN',
  //                'AREA',
  //                'SCHOOL',
  //                'EQUIPMENT',
  //              ],
  //            },
  //            {
  //              title: '迟到',
  //              url: '/manage/check/checkteacher/checkteacherthree',
  //              roles: [
  //                'CLASSADMIN',
  //                'CLASSVISTOR',
  //                'ADMIN',
  //                'AREA',
  //                'SCHOOL',
  //                'EQUIPMENT',
  //              ],
  //            },
  //            {
  //              title: '早退',
  //              url: '/manage/check/checkteacher/checkteacherfour',
  //              roles: [
  //                'CLASSADMIN',
  //                'CLASSVISTOR',
  //                'ADMIN',
  //                'AREA',
  //                'SCHOOL',
  //                'EQUIPMENT',
  //              ],
  //            },
  //          ],
  //        },
  //      ],
  //    },
  //  ],
  // },
]
