/**
 * @description 根据url返回视频的第一帧
 * @author fanzhongxu
 * @param {*} url 视频url
 * @returns 第一帧图片的base64
 */
export default url => {
  return new Promise(function(resolve, reject) {
    let dataURL = ''
    const video = document.createElement('video')
    video.setAttribute('crossOrigin', 'anonymous') // 处理跨域
    video.setAttribute('src', url)
    video.setAttribute('width', 400)
    video.setAttribute('height', 240)
    video.addEventListener('loadeddata', function() {
      const canvas = document.createElement('canvas')
      const width = video.width // canvas的尺寸和图片一样
      const height = video.height
      canvas.width = width
      canvas.height = height
      canvas.getContext('2d').drawImage(video, 0, 0, width, height) // 绘制canvas
      dataURL = canvas.toDataURL('image/jpeg') // 转换为base64
      resolve(dataURL)
    })
  })
}
