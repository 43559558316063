import { aesEncrypt, aesDecrypt } from '_utils'
const isDev = process.env.NODE_ENV === 'development'

export default {
  beforeRouteLeave(to, from, next) {
    if (isDev) {
      next()
      return
    }
    const query = to.query
    if (query?._m) next()
    else if (query && Object.keys(query).length > 0) {
      next({
        path: to.path,
        query: {
          _m: aesEncrypt(JSON.stringify(to.query)),
        },
      })
    } else next()
  },
  beforeRouteEnter(to, from, next) {
    if (isDev) {
      next()
      return
    }
    const m = to.query._m
    if (m) {
      Object.assign(to.query, JSON.parse(aesDecrypt(m)))
      delete to.query._m
    }
    next()
  },
}
