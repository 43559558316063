/**
 * * AES 加密解密方法
 * @author fanzhongxu
 */
import crypto from 'crypto'

const dKey = 'vpxLno996k5G3Psr'
const dIv = 'nLEDzjWjno996cY9'

/**
 * @description AES加密
 * @author fanzhongxu
 * @param {*} data 要加密的数据
 * @param {*} key 密钥
 * @returns 加密后的数据
 */
export const aesEncrypt = (data, key = dKey, iv = dIv) => {
  let sign = ''
  const cipher = crypto.createCipheriv('aes-128-cbc', key, iv)
  sign += cipher.update(data, 'utf8', 'hex')
  sign += cipher.final('hex')
  return sign
}

/**
 * @description AES解密
 * @author fanzhongxu
 * @param {*} encrypted 要解密的加密数据
 * @param {*} key 密钥
 * @returns 解密后的数据
 */
export const aesDecrypt = (encrypted, key = dKey, iv = dIv) => {
  let src = ''
  const cipher = crypto.createDecipheriv('aes-128-cbc', key, iv)
  src += cipher.update(encrypted, 'hex', 'utf8')
  src += cipher.final('utf8')
  return src
}
