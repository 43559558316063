/**
 * * 路由信息配置文件
 */

import Vue from 'vue'
import store from '_store'
import VueRouter from 'vue-router'
import { ajaxRequest } from '_library/ajax'
import { lStorage as storageUtil } from '_library/storage'
import config from '_config'

// 导入自定义路由
import routes from './routes'

import { rolesEnum } from '_enums/index.js'

const TAHT = Vue.prototype

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// 路由前置守卫
router.beforeEach((to, from, next) => {
  // 先终止所有ajax请求
  ajaxRequest.clearPending()

  TAHT.$Loading.start()

  const { userInfo, isLogin } = store.state.loginData
  const { auth, roles } = to.meta

  if (to.name === 'Login' && isLogin) next('/')

  // 验证页面是否需要登录
  if (auth || roles) {
    if (!isLogin) {
      const { isRember } = JSON.parse(
        storageUtil.getItem(`${config.sys_name}_cache`) || '{}'
      )
      if (!isRember) {
        store.commit('logout')
        next('/login')
      }
    }
  }

  // 验证用户权限
  if (roles) {
    const userRole = userInfo.userRole
    const roleName = rolesEnum[userRole].toLowerCase()
    !roles.map(k => k.toLowerCase()).includes(roleName) && next('/403')
  }

  // 设置页面标题
  if (to.meta.title) document.title = to.meta.title
  next()
})

// 路由后置守卫
router.afterEach((to, from) => {
  TAHT.$Loading.finish()
})

// 修改push方法
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router
