// import { $get, $post, $patch, $put, $delete } from '_library/ajax'
import { $get, $post } from '_library/ajax'

const list = {
  'get-information-by-page': '/information/get-information-by-page', // 分页获取节目信息
  'upload-file': '/information/upload-file', // 上传文件
  'add-information': '/information/add-information', // 新增节目
  'update-information': '/information/update-information', // 修改节目
  'delete-information': '/information/delete-information', // 删除节目
  'get-region-school-group-tree': '/school/get-region-school-group-tree', // 返回区域学校小组tree
  'get-school-use-list': '/user/get-school-use-list', // 返回学校关联用户
  'get-task-by-page': '/equipment/get-task-by-page', // 分页获取任务
  'get-orders-by-task': '/equipment/get-orders-by-task', // 获取命令
  'get-users-by-task': '/equipment/get-users-by-task', // 获取下发人
  'resend-order': '/equipment/resend-order', // 重新下发
  'close-order': '/equipment/close-order', // 关闭命令
  'close-information': '/equipment/close-information', // 关闭节目
  'get-users': '/information/get-users', // 获取创建人
  'send-wallpaper': '/equipment/wallpaper', // 壁纸
  'get-equipment-list-by-app': '/equipment/get-equipment-list-by-app', // 壁纸
  'desktop-live-broadcast': '/equipment/desktop-live-broadcast', // 桌面直播
  // 登录用户关联设备
  'get-login-user-equipments-service-list':
    '/equipment/get-login-user-equipments-service-list',
}

const methods = {
  /* 根据名称查询申请单 */
  getInformationByPage: data => $get('get-information-by-page', data),
  addInformation: data => $post('add-information', data),
  updateInformation: data => $post('update-information', data),
  deleteInformation: data => $post('delete-information', data),
  getRegionSchoolGroupTree: data => $get('get-region-school-group-tree', data),
  getSchoolUseList: data => $get('get-school-use-list', data),
  getUsersByTask: data => $get('get-users-by-task', data),
  resendOrder: data => $post('resend-order', data),
  closeOrder: data => $post('close-order', data),
  closeInformation: data => $post('close-information', data),
  getUsers: data => $get('get-users', data),
  sendWallpaper: data => $post('send-wallpaper', data),
  getEquipmentList: data => $get('get-equipment-list-by-app', data),
  desktopLiveBroadcast: data => $post('desktop-live-broadcast', data),

  getLoginUserEquipmentsServiceList: data =>
    $get('get-login-user-equipments-service-list', data),

  uploadFile: data => $post('upload-file', data), // 上传文件
}

export default { list, methods }
