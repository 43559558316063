import { lStorage as storageUtil } from '_library/storage'
import { $get } from '_library/ajax'
import config from '_config'

const actions = {
  async getUserInfo({ commit, state }) {
    let { ticket, isRember } = JSON.parse(
      storageUtil.getItem(`${config.sys_name}_cache`) || '{}'
    )
    if (state?.loginData?.ticket) {
      ticket = state?.loginData?.ticket
    }
    if (ticket) {
      await $get('get-user-by-id', null, {
        headers: { ticket },
        loading: false,
      })
        .then(res => {
          if (isRember) commit('login', res)
          else commit('setUserInfo', res)
        })
        .catch(() => {
          commit('logout')
        })
    }
  },
}

export default actions
