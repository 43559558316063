export default {
  path: '/policy',
  redirect: '/policy/distribution',
  component: () => import('_views/policyManagement/index'),
  children: [
    {
      path: 'distribution',
      meta: {
        auth: true,
        title: '下发管理',
      },
      component: () => import('_views/policyManagement/distribution'),
    },
    {
      path: 'distribution-policy',
      meta: {
        auth: true,
        title: '下发策略',
      },
      component: () => import('_views/policyManagement/distribution-policy'),
    },
    {
      path: 'policy-library',
      meta: {
        auth: true,
        title: '策略库',
      },
      component: () => import('_views/policyManagement/policy-library'),
    },
    {
      path: 'add-policy',
      meta: {
        auth: true,
        title: '添加策略',
      },
      component: () => import('_views/policyManagement/add-policy'),
    },
    {
      path: 'policy-detail',
      meta: {
        auth: true,
        title: '策略详情',
      },
      component: () => import('_views/policyManagement/policy-detail'),
    },
  ],
}
