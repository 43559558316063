import Vue from 'vue'
import {
  DatePicker,
  TimePicker,
  Tree,
  Button,
  Checkbox,
  Input,
  transfer,
  Form,
  FormItem,
} from 'element-ui'

import './theme/index.css'

// element-ui
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Tree)
Vue.use(Button)
Vue.use(Checkbox)
Vue.use(Input)
Vue.use(transfer)
Vue.use(Form)
Vue.use(FormItem)
