// * 白色带阴影卡片
<template>
  <div class="widget-box">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'WidgetBox',
}
</script>
