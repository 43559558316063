// * AlertConfirm组件
<template>
  <div
    class="alert-confirm"
    style="background-color: rgba(0, 0, 0, 0.3);"
    @click="showAlertConfirm($event)"
  >
    <transition name="show">
      <div class="alert-confirm">
        <div class="alert-inner">
          <!-- <div class="inner__header">header</div> -->
          <div class="alert-inner__content">
            <img src="~_images/alert.png" />
            <p>
              {{ content }}
            </p>
          </div>
          <div class="alert-inner__footer">
            <Button
              class="btm_item"
              type="primary"
              ghost
              @click="onCancel"
              id="cancelButton"
              >{{ cancelText }}</Button
            >
            <Button
              class="btm_item"
              type="primary"
              @click="onConfirm"
              id="confirmButton"
              >{{ okText }}</Button
            >
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AlertConfirmCom',

  components: {},

  props: {
    content: {
      type: String,
      default: '是否确认此操作？',
    },
    okText: {
      type: String,
      default: '确认',
    },
    cancelText: {
      type: String,
      default: '取消',
    },
    onConfirm: {
      type: Function,
      default: () => {},
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {}
  },

  computed: {},

  watch: {},

  created() {},

  mounted() {},

  methods: {
    // TODO 实现 点击弹窗外 关闭弹窗
    showAlertConfirm(event) {
      const ai = document.querySelector('.alert-inner')
      if (!ai.contains(event.target)) {
        this.onCancel()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.alert-confirm {
  //TODO 账号管理 删除框位置
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;

  .alert-inner {
    width: 610px; //TODO 批量删除 提示框大小设置
    min-height: 320px;
    background: #ffffff; //TODO 账号管理 点击删除按钮 出现提示框背景颜色
    box-shadow: 0px 2px 10px 0px #9c9ea0; //TODO 提示框投影颜色
    border-radius: 8px;
    padding: 50px 105px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__content {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      // white-space: pre-wrap;
      // word-wrap: break-word;

      .ivu-icon {
        margin-right: 20px;
        font-size: 18px;
        color: #ffa725;
      }
      img {
        width: 49px;
        height: 48px;
      }
      p {
        margin-top: 23px;
        margin-bottom: 20px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000d9; //TODO 账号管理 点击删除 弹出提示框 字体颜色
        line-height: 25px;
        word-wrap: break-word;
      }
    }
    &__footer {
      text-align: center;
      display: flex;
      justify-content: center;
      .ivu-btn:not(:first-of-type) {
        margin-left: 50px;
      }
      .btm_item {
        border-radius: 25px;
      }
    }
  }
}
.show-enter-active,
.show-leave-active {
  transition: all 0.2s ease;
}
.show-enter,
.show-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
#cancelButton {
  &:hover {
    background-color: #0f8dff !important; //TODO 设备管理 控制提示框 选择取消按钮时颜色
  }
}
#confirmButton {
  color: #ffffff;
  background-color: #0f8dff;
  border-color: #0f8dff;
  &:hover {
    background-color: #0f8dff !important;
    opacity: 0.8 !important ;
  }
}
</style>
