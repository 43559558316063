/**
 * * 自定义表格导出，并下载
 * @author fanzhongxu
 */

import Excel from 'exceljs'

/**
 * @description 导出Excel
 * @param {*} { fileName = '模板', bookInfo = {}, sheets }
 * @reference https://github.com/exceljs/exceljs/blob/master/README_zh.md
 * @example
 * exportExcel({
        fileName: '报告模板',
        bookInfo: {},
        sheets: [
          {
            name: 'sheet1',
            headInfo: '第一页数据',
            columns: [
              { header: 'Id', key: 'id', width: 10 },
              { header: 'Name', key: 'name', width: 32 },
              { header: 'D.O.B.', key: 'dob', width: 10, outlineLevel: 1 },
            ],
            data: [
              { id: '0001', name: 'John Doe', dob: new Date(1970, 1, 1) },
              { id: '0002', name: 'Jane Doe', dob: new Date(1965, 1, 7) },
            ],
          },
          {
            name: 'sheet2',
            headInfo: '第二页数据',
            columns: [
              { header: 'Id', key: 'id', width: 10 },
              { header: 'Name', key: 'name', width: 32 },
              { header: 'D.O.B.', key: 'dob', width: 10, outlineLevel: 1 },
            ],
            data: [
              { id: '0001', name: 'John Doe', dob: new Date(1970, 1, 1) },
              { id: '0002', name: 'Jane Doe', dob: new Date(1965, 1, 7) },
            ],
          },
        ],
      })
 */
export default ({ fileName = '模板', bookInfo = {}, sheets }) => {
  const workbook = new Excel.Workbook()
  // 设置工作簿 信息
  objAssign(bookInfo, workbook)

  // 添加工作表
  for (const sheetData of sheets) {
    const {
      name,
      columns,
      data,
      columns1,
      data1,
      headInfo,
      columns2,
      columns3,
      data3,
    } = sheetData
    const sheet = workbook.addWorksheet(name)

    sheet.getRow(1).values = new Array(columns.length).fill(headInfo)
    sheet.getRow(1).height = 100
    sheet.getRow(1).font = {
      size: 20,
    }
    sheet.getRow(2).values = columns.map(col => col.header)
    sheet.getRow(2).font = {
      size: 14,
    }
    sheet.columns = columns.map(({ header, ...other }) => other)

    sheet.addRows(data)

    sheet.getRow(sheet.getRows.length + data.length + 2).values = columns2.map(
      col => col.header
    )
    sheet.getRow(sheet.getRows.length + data.length + 2).font = {
      size: 14,
    }
    sheet.getRow(sheet.getRows.length + data.length + 3).values = columns1.map(
      col => col.header
    )
    sheet.columns = columns1.map(({ header, ...other }) => other)
    sheet.addRows(data1)

    sheet.getRow(
      sheet.getRows.length + data.length + 5 + data1.length
    ).values = columns3.map(col => col.header)

    sheet.getRow(sheet.getRows.length + data.length + 5 + data1.length).font = {
      size: 14,
    }

    var num =
      columns.length > columns1.length ? columns.length : columns1.length // 整体行数

    sheet.columns = columns3.map(({ header, ...other }) => other)
    sheet.addRows(data3)
    sheet.mergeCells(`A1:${String.fromCharCode(64 + num + 1)}1`)

    console.log(columns)

    sheet.columns.forEach((item, i) => {
      sheet.getColumn(i + 1).width = 30

      sheet.getColumn(i + 1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
    })
  }

  workbook.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    triggerDownload(blob, fileName)
  })

  function objAssign(objA, objB) {
    for (const key in objA) objB[key] = objA[key]
  }
  // 触发下载
  function triggerDownload(downData, downName) {
    if ('msSaveOrOpenBlob' in navigator) {
      // edge 和 ie10-11
      window.navigator.msSaveOrOpenBlob(downData, downName)
    } else {
      // Chrome,Firefox 等浏览器
      var href = URL.createObjectURL(downData) // 创建对象超链接
      const outFile = document.createElement('a')
      outFile.download = `${downName}` // 下载名称
      outFile.href = href // 绑定a标签
      outFile.click() // 模拟点击实现下载
      setTimeout(function() {
        // 延时释放
        URL.revokeObjectURL(downData) // 用URL.revokeObjectURL()来释放这个object URL
      }, 100)
    }
  }
}
