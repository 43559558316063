/**
 * * 自动注册全局组件
 * * 自动注册components目录下所有vue文件，以name选项为组件名称
 * * 自动排除以_为首字母命名的文件,例如：_header.vue
 */

const requireComponent = require.context('./', true, /.*\/(?!\/)[^_\d]+\.vue$/)

const components = requireComponent.keys().reduce((total, fileName) => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName)

  // 获取组件的 PascalCase 命名
  const componentName = componentConfig.default.name

  if (!componentName)
    throw new Error(`Please give a component's name in the ${fileName}!`)

  total[componentName] = componentConfig.default || componentConfig

  return total
}, {})

export default components
