// 文件类型参数映射

const typeMap = {
  office: ['doc', 'docx', 'ppt', 'pptx', 'xlsx', 'xml', 'xls', 'pdf', 'wps'],
  compressed: ['rar', 'zip'],
  photo: ['png', 'jpg', 'jpeg', 'ktr'],
  datasource: ['dbf'],
  video: ['avi', 'mov', 'rmvb', 'mp4'],
  audioFrequency: ['mp3', 'mid', 'aif', 'aiff', 'wav'],
  application: ['exe'],
  all: [], // 不验证
}
const getType = suffixName => {
  const keys = Object.keys(typeMap)
  for (let index = 0; index < keys.length; index++) {
    if (typeMap[keys[index]].includes(suffixName)) {
      return keys[index]
    }
  }
  return null
}

export default getType
