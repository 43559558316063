import store from '_store'
import { rolesEnum } from '_enums'
export default {
  path: '/after',
  redirect: '/after/repair',
  component: () => import('_views/after/index'),
  children: [
    {
      path: 'repair',
      meta: {
        auth: true,
        title: '设备报修',
      },
      component: () => import('_views/after/EquipmentRepair/index'),
    },
    {
      path: 'addRepair',
      meta: {
        auth: true,
        title: '设备报修',
      },
      component: () => import('_views/after/EquipmentRepair/addRepair'),
    },
    {
      path: 'feedback',
      meta: {
        auth: true,
        title: '需求反馈',
      },
      beforeEnter: (to, from, next) => {
        const role = store.state.loginData.userInfo.userRole
        console.log(role)

        if (+role === rolesEnum.ADMIN) next({ name: 'FeedbackManage' })
        else next({ name: 'FeedbackAdd' })
      },
    },
    {
      path: 'feedback/manage',
      name: 'FeedbackManage',
      meta: {
        auth: true,
        title: '反馈管理',
        roles: ['ADMIN'],
      },
      component: () => import('_views/after/FeedBackManage/index'),
      props: true,
    },
    {
      path: 'feedback/add',
      name: 'FeedbackAdd',
      meta: {
        auth: true,
        title: '反馈添加',
        roles: ['AREA', 'SCHOOL', 'EQUIPMENT'],
      },
      component: () => import('_views/after/FeedBackAdd/index'),
      props: true,
    },
    {
      path: 'feedback/manage/:id',
      meta: {
        auth: true,
        title: '反馈详情',
        roles: ['ADMIN'],
      },
      component: () => import('_views/after/FeedBackDetail/index'),
      props: true,
    },
  ],
}
