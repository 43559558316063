<template>
  <div class="layout-top-header">
    <div class="logo pointer" v-if="showLogo" @click="$router.push('/')">
      创维集控系统
    </div>
    <SearchInput
      v-if="showSearch"
      class="search"
      v-model="keyword"
      @on-search="k => $emit('on-search', k)"
    />

    <div :class="['user', { 'm-l-a': !showSearch && !showSearch }]">
      <Dropdown>
        <div class="dropdown-item">
          <Icon class="user-avatar" type="md-contact" />
          <span class="user-name">{{ userName }}</span>
        </div>

        <DropdownMenu slot="list">
          <DropdownItem @click.native="$router.push({ name: 'Profiles' })"
            >个人中心</DropdownItem
          >
          <!-- <DropdownItem
            @click.native="$router.push({ name: 'ModifyPassword' })"
          >
            修改密码
          </DropdownItem>-->
          <DropdownItem @click.native="handleLogout">退出登录</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <div class="systemMessage">
        <Divider type="vertical" class="divider" />
        <Icon
          type="ios-notifications-outline"
          size="26"
          @click.native="$router.push({ name: 'SystemMessage' })"
        ></Icon>
        <!-- <Badge dot v-if="loginisMessage === '1'"></Badge> -->
        <Badge
          @click.native="$router.push({ name: 'SystemMessage' })"
          :count="messageNum"
          class-name="demo-badge-alone"
          overflow-count="99"
        ></Badge>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { sStorage } from '_library/storage'
import { mapApi } from '_utils'
export default {
  name: 'Header',

  components: {},

  props: {
    showLogo: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      keyword: '',
    }
  },

  computed: {
    ...mapState({
      userName: state => state.loginData?.userInfo?.userName || '用户',
      loginisMessage: state => state.loginData?.userInfo?.isMessage,
      messageNum: state => state.loginData?.userInfo?.messageNum,
      // shuangliu_ticket: state => state.loginData?.userInfo?.shuangliu_ticket,
    }),
  },

  watch: {},

  created() {
    if (sStorage.getItem('type') === 'shuangliu') {
      this.validateSSOIsLogin({
        shuangliu_ticket: sStorage.getItem('shuangliu_ticket'),
        type: 'shuangliu',
      })
        .then(({ isLogin, url }) => {
          if (!isLogin) {
            this.handleLogout()
            if (url) {
              window.location.href = url
            }
          }
        })
        .catch()
    }
  },

  mounted() {},

  methods: {
    ...mapMutations(['logout']),
    ...mapApi(['validateSSOIsLogin']),
    handleLogout() {
      this.logout()
      if (sStorage.getItem('type') === 'shuangliu') {
        const logoutPath = sStorage.getItem('logoutPath')
        window.location.href = logoutPath
      } else this.$router.push('/login')
    },
  },
}
</script>

<style lang="scss" scoped>
.layout-top-header {
  display: flex;
  align-items: center;

  .logo {
    font-size: 20px;
    font-weight: 500;
    color: #000000d9; //TODO 巡课模式 ”创维集控系统“字体颜色
  }
  .search {
    margin-left: auto;
    width: 300px;
    margin-right: 80px;
  }
  .user {
    cursor: pointer;
    height: 100%;

    display: flex;
    align-items: center;

    &.m-l-a {
      margin-left: auto;
    }
    .dropdown-item {
      display: flex;
      align-items: center;

      & /deep/ .ivu-icon-ios-arrow-down {
        margin-left: 11px;
        transition: 0.3s;
        transition-delay: 0.3s;
        &::before {
          content: '\e614';
          font-family: 'font_family';
        }
      }
      &:hover {
        & /deep/ .ivu-icon-ios-arrow-down {
          transform: rotateZ(180deg);
        }
      }
    }
    &-avatar {
      font-size: 33px;
      color: #0f8dff;
      margin-right: 15px;
      vertical-align: -10px;
    }
    &-name {
      //TODO 用户名颜色
      color: rgba(0, 21, 41, 1);
      display: inline-block;
      max-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 33px;
      line-height: 33px;
    }

    .systemMessage {
      display: inline-block;
      position: relative;
      color: #0f8dff;
      // top: -10%;
      .ivu-divider {
        & /deep/ .ivu-divider-vertical {
          background: rgba(255, 255, 255, 0.23);
          border-radius: 1px;
        }
      }
      .divider {
        background: rgba(255, 255, 255, 0.23);
        border-radius: 1px;
      }
      & /deep/ .ivu-badge-count-alone {
        position: relative;
        top: -11px;
        right: 8px;
      }
    }
  }
}
</style>
