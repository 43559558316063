// * 应用管理

import { $get, $post } from '_library/ajax'
const list = {
  'send-application': '/application/send-application',
  'get-application-by-list': '/application/get-application-by-list',
  'get-send-application': '/application/get-send-application',

  'application-update-application': '/application/update-application',
  'application-add-application': '/application/add-application',

  'application-send-application': '/application/send-application',
  'application-get-application-by-list': '/application/get-application-by-list',
  'application-get-application-by-id': '/application/get-application-by-id',
  'application-delete-application': '/application/delete-application',
}
const methods = {
  sendApplication: data => $post('send-application', data), // 下发应用
  getApplicationByList: data => $get('get-application-by-list', data), // 获取应用列表
  getSendApplication: data => $get('get-send-application', data), // 获取应用列表

  addApp: data => $post('application-add-application', data),
  updateApp: data => $post('application-update-application', data),
  sendApp: data => $post('application-send-application', data),
  getAppList: data => $get('application-get-application-by-list', data),

  getAppInfo: data => $get('application-get-application-by-id', data),
  deleteApp: data => $post('application-delete-application', data),
}
export default {
  namespace: 'application',
  list,
  methods,
}
