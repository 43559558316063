<template>
  <div>
    <div class="pwdTipContent" v-show="isShowTip">
      <div class="contents" style="text-align: start;padding-left: 20px;">
        <div v-if="degree">
          <i
            :class="{
              active: bPwd === false,
              'el-icon-success': bPwd,
            }"
          ></i>
          <span class="degreeWeak">
            <i
              :class="{
                active: bPwd === false,
                'el-icon-circle-check': !bPwd,
              }"
            ></i>
            至少6位字符</span
          >
        </div>
        <div v-else>至少6位字符</div>
        <div v-if="degreeNumber == 2">
          <i
            :class="{
              'el-icon-circle-check': !bPwd,
              active: bPwd === false,
              'el-icon-success': bPwd,
            }"
          ></i>
          <span class="degreeMiddle">字母、数字、特殊字符至少两种组合</span>
        </div>
        <div v-else>
          <i
            :class="{
              'el-icon-success': bPwd,
              active: bPwd === false,
              'el-icon-circle-check': !bPwd,
            }"
          ></i>
          <span>字母、数字、特殊字符至少两种组合</span>
        </div>

        <div v-if="isShow">
          <i
            :class="{
              'el-icon-circle-check': !bPwd,
              active: bPwd === false,
              'el-icon-success': bPwd,
            }"
          ></i>
          <span class="degreeStrong">不超过20位字符</span>
        </div>
        <div v-else>
          <i
            :class="{
              active: bPwd === false,
              'el-icon-circle-check': !bPwd,
            }"
          ></i>
          <span>不超过20位字符</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'verifyPassWordTip',
  props: {
    password: {
      type: String,
    },
    isShowTip: {
      type: Boolean,
    },
  },
  data() {
    return {
      isShow: false,
      degree: false,
      bPwd: null, // 密码值和长度的验证
      degreeNumber: 0, // 密码复杂度的验证
    }
  },
  watch: {
    password() {
      this.initPassWord()
    },
  },
  methods: {
    /**
     * 密码加载
     **/
    initPassWord() {
      if (!this.password) {
        this.bPwd = null // 没有值标签背景置灰
        this.degreeNumber = 0
      } else {
        const objReturn = this.passwordValid(this.password)
        if (objReturn) {
          this.bPwd = objReturn.bPwd
          this.degreeNumber = objReturn.degreeNumber
        }
      }
    },
    /**
     * 密码验证（密码复杂度：(1)大写字母(2)小写字母(3)数字(4)特殊符号，密码至少需包含上述情形中的三种）
     * @param valPWD输输入的密码信息
     * @return 返回验证信息
     **/
    passwordValid(valPwd) {
      let msg = ''
      let degreeNumber = 0
      let bPwd = false
      if (valPwd === null || valPwd.length < 6 || valPwd.trim().length === '') {
        bPwd = false
      } else {
        bPwd = true
      }
      if (valPwd.length > 20) {
        this.isShow = false
      } else {
        this.isShow = true
      }
      const arrVerify = [
        { regName: 'Number', regValue: /^.*[0-9]+.*/ },
        { regName: 'LowerCase', regValue: /^.*[a-z]+.*/ },
        { regName: 'UpperCase', regValue: /^.*[A-Z]+.*/ },
      ]
      let regNum = 0 // 记录匹配的次数
      for (let iReg = 0; iReg < arrVerify.length; iReg++) {
        if (arrVerify[iReg].regValue.test(valPwd)) {
          regNum = regNum + 1
          this.degree = true
        }
      }
      degreeNumber = regNum
      if (regNum <= 2) {
        msg = '密码必须包含大小写字母和数字'
      }
      const objReturn = {
        msg: msg,
        degreeNumber: degreeNumber,
        bPwd: bPwd,
      }
      return objReturn
    },
  },
}
</script>
<style lang="less" scoped>
.pwdTipContent .el-icon-circle-check {
  color: #ffff;
}
.pwdTipContent .el-icon-circle-check.active {
  color: red; //TODO 修改密码 提示框图标颜色
}
.pwdTipContent .el-icon-success {
  color: rgba(44, 222, 152, 1);
}
.pwdTipContent {
  position: absolute;
  min-width: 350px;
  height: 137px;
  background: #fff; //TODO 修改密码 提示框背景颜色
  box-shadow: 0px 2px 20px 0px #d2d5de; //TODO 修改密码 提示框阴影颜色
  right: 143px;
  margin-top: 64px;
  border-radius: 4px;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000d9; //TODO 修改密码 提示框字体颜色
  line-height: 36px;
}
.contents {
  text-align: start;
  padding-left: 20px;
}
.pwdTipContent:after {
  width: 0;
  height: 0;
  overflow: hidden;
  content: '';
}
.pwdTipContent:after {
  position: absolute;
  top: -10px;
  left: 20px;
  border-bottom: 10px solid #fff; //TODO 修改密码 提示框三角颜色
  box-shadow: 0px 2px 20px 0px #d2d5de; //TODO 修改密码 提示框三角阴影颜色
  border-right: 10px dashed transparent;
  border-left: 10px dashed transparent;
}
</style>
