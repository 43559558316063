import Vue from 'vue'
import axios from 'axios'
import store from '_store/index'
import config from '_config/index'
import { apiList } from '../api'
import vueInstance from '@/main'

const THAT = Vue.prototype

class HttpRequest {
  constructor() {
    if (!HttpRequest.instance) {
      this.pending = new Map() // 请求队列
      HttpRequest.instance = this
    }
    return HttpRequest.instance
  }

  /**
   * @description 获取axios默认配置
   * @author fanzhongxu
   * @returns config
   */
  getDefaultConfig() {
    return {
      baseURL: config.baseURL,
    }
  }

  /**
   * @description 显示全局loading
   * @author fanzhongxu
   */
  showLoading() {
    const len = [...this.pending.values()].filter(request => request.loading)
      .length
    if (len === 0) {
      // 显示spin
      // store.commit('setSpinning', true)
      THAT.$Spin.show({
        render: h => {
          return h('div', [
            h('Icon', {
              class: 'spin-icon-load',
              props: {
                type: 'ios-loading',
                size: 18,
              },
            }),
            h('div', { class: 'load-icon-text' }, '加载中...'),
          ])
        },
      })
    }
  }

  /**
   * @description 关闭全局Loading
   * @author fanzhongxu
   */
  hideLoading() {
    const len = [...this.pending.values()].filter(request => request.loading)
      .length
    if (len <= 0) {
      // 隐藏spin
      // store.commit('setSpinning', false)
      THAT.$Spin.hide()
    }
  }

  /**
   * @description 请求&响应拦截器
   * @author fanzhongxu
   * @param {Object} instance axios实例
   * @param {String} url api-url
   * @param {Boolean} loading 全局loading
   */
  interceptors(instance, options) {
    const { loading } = options
    instance.interceptors.request.use(
      config => {
        loading && this.showLoading()
        this.deletePending(config)
        this.addPending(config)
        return config
      },
      err => {
        return Promise.reject(err)
      }
    )

    instance.interceptors.response.use(
      res => {
        this.deletePending(options)
        loading && this.hideLoading()
        const { data } = res
        return data
      },
      err => {
        if (axios.isCancel(err))
          console.warn(`[ajax warn]: 请求重复: ${err.message}`)
        else this.deletePending(options)

        loading && this.hideLoading()

        return Promise.reject(err)
      }
    )
  }

  /**
   * @description 请求方法
   * @author fanzhongxu
   * @param {*} options axios配置
   * @returns 请求结果
   */
  request(options) {
    const instance = axios.create()

    options = Object.assign({}, this.getDefaultConfig(), options)

    this.interceptors(instance, options)

    return instance(options)
  }

  addPending(config) {
    const { url, method, params, data, loading } = config
    const key = [
      url,
      method.toUpperCase(),
      JSON.stringify(params),
      JSON.stringify(data),
    ].join('&')

    config.cancelToken = new axios.CancelToken(cancel =>
      this.pending.set(key, { cancel, loading })
    )
  }

  deletePending(config) {
    const { url, method, params, data } = config
    const key = [
      url,
      method.toUpperCase(),
      JSON.stringify(params),
      JSON.stringify(data),
    ].join('&')

    if (this.pending.has(key)) {
      const cancel = this.pending.get(key)?.cancel
      cancel && cancel(url)
      this.pending.delete(key)
    }
  }

  clearPending() {
    for (const [url, request] of this.pending) {
      request.cancel(url)
    }
    this.pending.clear()
  }
}

export const ajaxRequest = new HttpRequest()

/**
 * @description ajax方法
 * @author fanzhongxu
 * @param {String} url api接口地址
 * @param {Object} params api-get接口参数
 * @param {Object} data api-post接口参数
 * @param {String} method api接口方式
 * @param {String} loading 是否显示全局loading
 * @param {Object} header 请求头信息
 */
const _ajax = function({ url, params, data, method, loading, headers }) {
  headers = Object.assign(
    {},
    { ticket: store.state?.loginData?.ticket },
    headers
  )
  return new Promise((resolve, reject) => {
    ajaxRequest
      .request({
        url,
        params,
        data,
        method,
        headers,
        loading,
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        const logoutandlogin = function() {
          vueInstance.$store.commit('logout')
          vueInstance.$router.push('/login')
        }

        if (err.response && err.response.status === 404) {
          THAT.$alertError('网络中断，请稍后重试')
        } else if (err.response && err.response.status === 500) {
          THAT.$alertError('网络繁忙，请稍后重试')
        } else if (err.response && err.response.status === 503) {
          THAT.$alertError('服务器异常，请稍后重试')
        } else if (err.response && err.response.status === 401) {
          THAT.$store.commit('logout')
          THAT.$router.push('/login')
        } else if (err.response && err.response.status === 504) {
          vueInstance.$router.push({ name: 'DataException' })
        } else if (err.response && err.response.status === 403) {
          logoutandlogin()
          vueInstance.$alertError('会话超时，请重新登录')
        } else if (err.response) {
          const data = err.response.data
          data && data.msg && THAT.$alertError(data.msg)
        } else if (err.request) {
          // console.log("🚀 ~ ajaxRequest ~ err.request:", err.request)
        }
        reject(err)
      })
  })
}

/**
 * @description ajax-get方法
 * @author fanzhongxu
 * @param {String} api api接口名
 * @param {Object} params api接口参数
 * @param {Boolean} loading 是否显示全局loading，默认false（不显示）
 * @param {Object} header 请求头信息 默认空对象{}
 */
export const $get = function(
  api,
  params = {},
  { loading = true, headers = {} } = {}
) {
  const options = {
    url: apiList[api],
    params,
    method: 'GET',
    loading,
    headers,
  }
  return _ajax(options)
}

/**
 * @description ajax-post方法
 * @author fanzhongxu
 * @param {String} api api接口名
 * @param {Object} data api接口参数
 * @param {Boolean} loading 是否显示全局loading，默认false（不显示）
 * @param {Object} header 请求头信息 默认空对象{}
 */
export const $post = function(
  api,
  data = {},
  { loading = true, headers = {} } = {}
) {
  const options = {
    url: apiList[api],
    data,
    method: 'POST',
    loading,
    headers,
  }
  return _ajax(options)
}

/**
 * @description ajax-delete方法
 * @author fanzhongxu
 * @param {String} api api接口名
 * @param {Object} params api接口参数
 * @param {Boolean} loading 是否显示全局loading，默认false（不显示）
 * @param {Object} header 请求头信息 默认空对象{}
 */
export const $delete = function(
  api,
  params = {},
  { loading = true, headers = {} } = {}
) {
  const options = {
    url: apiList[api],
    params,
    method: 'DELETE',
    loading,
    headers,
  }
  return _ajax(options)
}

/**
 * @description ajax-put方法
 * @author fanzhongxu
 * @param {String} api api接口名
 * @param {Object} params api接口参数
 * @param {Boolean} loading 是否显示全局loading，默认false（不显示）
 * @param {Object} header 请求头信息 默认空对象{}
 */
export const $put = function(
  api,
  data = {},
  { loading = true, headers = {} } = {}
) {
  const options = {
    url: apiList[api],
    data,
    method: 'PUT',
    loading,
    headers,
  }
  return _ajax(options)
}

/**
 * @description ajax-put方法
 * @author fanzhongxu
 * @param {String} api api接口名
 * @param {Object} params api接口参数
 * @param {Boolean} loading 是否显示全局loading，默认false（不显示）
 * @param {Object} header 请求头信息 默认空对象{}
 */
export const $patch = function(
  api,
  data = {},
  { loading = true, headers = {} } = {}
) {
  const options = {
    url: apiList[api],
    data,
    method: 'PATCH',
    loading,
    headers,
  }
  return _ajax(options)
}

/**
 * @description ajax-自定义方法
 * @author fanzhongxu
 * @param {String} url 接口地址
 * @param {String} method 请求方式
 * @param {Object} data api接口参数
 * @param {Boolean} loading 是否显示全局loading，默认true（显示）
 * @param {Object} header 请求头信息 默认空对象{}
 */
export const $custom = function(
  url,
  method,
  data = {},
  { loading = true, headers = {} } = {}
) {
  const options = {
    url,
    method,
    loading,
    headers,
  }
  if (method.toUpperCase() === 'GET') options.params = data
  else options.data = data

  return _ajax(options)
}

const ajax = { $get, $post, $del: $delete, $put, $patch, $custom }

export default ajax
