export default {
  path: '/manage',
  component: () => import('_views/manage/index'),
  children: [
    {
      path: 'AccountIndex',
      name: 'AccountIndex',
      meta: {
        auth: true,
        title: '卡管理',
        roles: ['ADMIN', 'AREA', 'SCHOOL'],
      },
      children: [
        {
          path: 'AccountIndexOne',
          name: 'AccountIndexOne',
          meta: {
            auth: true,
            title: '车辆管理',
            roles: ['ADMIN', 'AREA', 'SCHOOL'],
          },
          component: () => import('_views/manage/index'),
        },
      ],
      component: () => import('_views/manage/index'),
    },
    {
      path: 'DeviceIndex',
      name: 'DeviceIndex',
      meta: {
        auth: true,
        title: '校园管理',
        roles: ['ADMIN', 'AREA', 'SCHOOL'],
      },
      children: [
        {
          path: 'DeviceIndexOne',
          name: 'DeviceIndexOne',
          meta: {
            auth: true,
            title: '学生出入管理',
            roles: ['ADMIN', 'AREA', 'SCHOOL'],
          },
          component: () => import('_views/manage/index'),
        },
        {
          path: 'DeviceIndexTwo',
          name: 'DeviceIndexTwo',
          meta: {
            auth: true,
            title: '师生宿舍管理',
            roles: ['ADMIN', 'AREA', 'SCHOOL'],
          },
          component: () => import('_views/manage/index'),
        },
        {
          path: 'DeviceIndexThree',
          name: 'DeviceIndexThree',
          meta: {
            auth: true,
            title: '教育资源管理',
            roles: ['ADMIN', 'AREA', 'SCHOOL'],
          },
          component: () => import('_views/manage/index'),
        },
        {
          path: 'DeviceIndexFour',
          name: 'DeviceIndexFour',
          meta: {
            auth: true,
            title: '课堂评级与分析',
            roles: ['ADMIN', 'AREA', 'SCHOOL'],
          },
          component: () => import('_views/manage/index'),
        },
      ],
      component: () => import('_views/manage/index'),
    },
    {
      path: 'check',
      name: 'check',
      meta: {
        auth: true,
        title: '考勤管理',
        roles: ['ADMIN', 'AREA', 'SCHOOL'],
      },
      children: [
        {
          path: 'checkstudent',
          name: 'checkstudent',
          meta: {
            auth: true,
            title: '学生考勤',
            roles: ['ADMIN', 'AREA', 'SCHOOL'],
          },
          children: [
            {
              path: 'checkstudentone',
              name: 'checkstudentone',
              meta: {
                auth: true,
                title: '正常',
                roles: ['ADMIN', 'AREA', 'SCHOOL'],
              },
              component: () => import('_views/manage/index'),
            },
            {
              path: 'checkstudenttwo',
              name: 'checkstudenttwo',
              meta: {
                auth: true,
                title: '缺课',
                roles: ['ADMIN', 'AREA', 'SCHOOL'],
              },
              component: () => import('_views/manage/index'),
            },
            {
              path: 'checkstudentthree',
              name: 'checkstudentthree',
              meta: {
                auth: true,
                title: '迟到',
                roles: ['ADMIN', 'AREA', 'SCHOOL'],
              },
              component: () => import('_views/manage/index'),
            },
            {
              path: 'checkstudentfour',
              name: 'checkstudentfour',
              meta: {
                auth: true,
                title: '早退',
                roles: ['ADMIN', 'AREA', 'SCHOOL'],
              },
              component: () => import('_views/manage/index'),
            },
          ],
          component: () => import('_views/manage/index'),
        },
        {
          path: 'checkteacher',
          name: 'checkteacher',
          meta: {
            auth: true,
            title: '教师考勤',
            roles: ['ADMIN', 'AREA', 'SCHOOL'],
          },
          children: [
            {
              path: 'checkteacherone',
              name: 'checkteacherone',
              meta: {
                auth: true,
                title: '正常',
                roles: ['ADMIN', 'AREA', 'SCHOOL'],
              },
              component: () => import('_views/manage/index'),
            },
            {
              path: 'checkteachertwo',
              name: 'checkteachertwo',
              meta: {
                auth: true,
                title: '缺课',
                roles: ['ADMIN', 'AREA', 'SCHOOL'],
              },
              component: () => import('_views/manage/index'),
            },
            {
              path: 'checkteacherthree',
              name: 'checkteacherthree',
              meta: {
                auth: true,
                title: '迟到',
                roles: ['ADMIN', 'AREA', 'SCHOOL'],
              },
              component: () => import('_views/manage/index'),
            },
            {
              path: 'checkteacherfour',
              name: 'checkteacherfour',
              meta: {
                auth: true,
                title: '早退',
                roles: ['ADMIN', 'AREA', 'SCHOOL'],
              },
              component: () => import('_views/manage/index'),
            },
          ],
          component: () => import('_views/manage/index'),
        },
      ],
      component: () => import('_views/manage/index'),
    },
  ],
}
