<template>
  <div class="box-section-page">
    <!--<div class="copy-pre-page" @click="prePage">
      <Icon class="iconfont icon-shangyiye1"></Icon>
    </div>-->
    <!--TODO 设备管理下拉框页码设置-->
    <Pager
      class="section-list-pager"
      @on-change="onPageChange"
      :total="page.total"
      :page-size="page.pageSize"
      :current="page.pageNumber"
      @on-page-size-change="onPageSizeChange"
      :page-size-opts="[9, 18, 27, 36, 45, 54]"
      transfer
    />
    <!--<div class="copy-next-page" @click="nextPage">
      <Icon class="iconfont icon-xiayiye"></Icon>
    </div>-->
  </div>
</template>

<script>
export default {
  name: 'widget-pageable',
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  watch: {
    page: {
      handler(arr) {},
      deep: true,
    },
  },
  methods: {
    prePage() {
      if (this.page.pageNumber < 2) {
        return
      }
      this.onPageChange(this.page.pageNumber - 1)
    },
    nextPage() {
      if (this.page.pageNumber === this.page.totalPageCount) {
        return
      }
      this.onPageChange(this.page.pageNumber + 1)
    },
    onPageChange(page) {
      this.$emit('on-change', page)
    },
    onPageSizeChange(pageSize) {
      console.log(pageSize, 777)
      this.$emit('on-change-size', pageSize)
    },
  },
  mounted() {},
}
</script>
<style scoped="scoped" lang="less">
.box-section-page {
  display: flex;
  justify-content: flex-end;
}
&/deep/ .ivu-page-options-sizer {
  .ivu-select-single {
    .ivu-select-selected-value {
      padding-left: 8px; //TODO 下拉框条数字体离左距离
    }
    .ivu-select-placeholder {
      padding-left: 0;
      height: 32px;
      line-height: 32px;
    }
  }
}
& /deep/ .ivu-table {
  border-radius: 4px;
}

& /deep/ .ivu-table .demo-table-info-row td {
  background-color: #f3f6fb !important;
}

/* warn:为了使校园通知页面颜色正常显示，做一下修改,暂不知对其它页面的影响 */
/* .ivu-table .demo-table-error-row td {
		background-color: #D5F1FF !important;
	} */
& /deep/ .ivu-table .demo-table-error-row td {
  background-color: #fff !important;
}
.box-section-table {
  width: 100%;
}

& /deep/ th:nth-of-type(1) {
  padding-left: 20px;
}
& /deep/ td:nth-of-type(1) {
  padding-left: 20px;
}

.alertWran {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  min-height: 200px;
  word-break: break-word;
  padding-top: 26px;
  color: red;
}
.box-section-title {
  margin: 23px 0px 32px 20px;
  width: 228px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(37, 53, 85, 1);
  line-height: 33px;
}
.copy-pre-page,
.copy-next-page {
  width: 90px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border-radius: 16px;
  border: 1px solid rgba(181, 210, 255, 1);
  text-align: center;
  color: #0f8dff;
  font-size: 12px;
  cursor: pointer;
  .iconfont {
    font-size: 14px;
  }
}
.copy-pre-page {
  margin-right: 10px;
}
</style>
