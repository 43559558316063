/**
 * * 所有第三方插件在这里注册
 */

import Vue from 'vue'

// 自定义基础插件
import basePlugin from './@base'

// fragment
import { Plugin } from 'vue-fragment'

import './@elementui'
import './@view_design'

Vue.use(basePlugin)

Vue.use(Plugin)
