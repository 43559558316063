import { $get, $post } from '_library/ajax'

const list = {
  'get-tactic-by-page': '/application/get-tactic-by-page', // 分页获取策略
  'add-tactics': '/application/add-tactics', // 添加策略
  'delete-tactics': '/application/delete-tactics', // 删除策略
  'get-tactic-by-id': '/application/get-tactic-by-id', // 根据id获取策略
  'update-tactics': '/application/update-tactics', // 编辑策略
  'get-send-tactics': '/application/get-send-tactics', // 获取策略下发记录
  'get-tactics': '/application/get-tactics', // 获取策略列表
  'send-tactics': '/application/send-tactics', // 下发策略
  'get-task-by-id': '/equipment/get-task-by-id', // 根据id获取任务
}

const methods = {
  getTacticByPage: data => $get('get-tactic-by-page', data),
  addTactics: data => $post('add-tactics', data),
  deleteTactics: data => $post('delete-tactics', data),
  getTacticById: data => $get('get-tactic-by-id', data),
  updateTactics: data => $post('update-tactics', data),
  getSendTactics: data => $get('get-send-tactics', data),
  getTactics: data => $get('get-tactics', data),
  sendTactics: data => $post('send-tactics', data),
  getTaskById: data => $get('get-task-by-id', data),
}

export default { list, methods }
