<template>
  <div class="flv-video-wrapper">
    <video
      class="video"
      v-show="status === StatusEnum.LOADED"
      ref="video"
      muted
      preload="preload"
      controls
      disablePictureInPicture
    ></video>
    <Empty v-show="status === StatusEnum.FAILED" v-bind="emptyInfo" />
    <Spin
      class="spin-loading"
      size="large"
      fix
      v-show="status === StatusEnum.LOADING"
    >
      <Icon type="ios-loading" size="18" class="spin-icon-load"></Icon>
      <div>加载中</div>
    </Spin>
  </div>
</template>

<script>
import mpegtsjs from 'mpegts.js'

const StatusEnum = {
  LOADING: '0',
  LOADED: '1',
  FAILED: '2',
}

export default {
  name: 'MpegtsVideo',

  components: {},

  props: {
    id: {
      type: String,
    },
    isIntranet: {
      type: Number,
    },
    url: {
      type: String,
    },
    emptyInfo: {
      type: Object,
      default: () => ({
        size: 'large',
        // imgSrc: ''
        text: '暂时无法获取桌面',
      }),
    },
  },

  data() {
    return {
      StatusEnum,
      // status: StatusEnum.LOADING,
      status: '0',
      reloadPlayer: null,
      timeId: null,
      videoElement: null,
    }
  },

  computed: {},

  watch: {
    url(url) {
      if (url) {
        this.delFlyPlayer()
        this.initPlayer()
      } else this.close()
    },
    status(status) {
      if (status === this.StatusEnum.LOADED) {
        clearInterval(this.timeId)
        this.timeId = null
      } else if (status === this.StatusEnum.LOADING) {
        this.timeId = setInterval(() => {
          if (this.reloadPlayer) {
            this.reloadPlayer.unload()
            this.load_play_player(this.reloadPlayer, this.videoElement)
          }
        }, 5000)
      }
    },
  },

  created() {},

  mounted() {
    if (this.url) {
      this.delFlyPlayer()
      this.initPlayer()
    }

    this.timeId = setInterval(() => {
      if (this.reloadPlayer) {
        this.reloadPlayer.unload()
        this.load_play_player(this.reloadPlayer, this.videoElement)
      }
    }, 5000)
  },

  methods: {
    async initPlayer() {
      this.status = this.StatusEnum.LOADING
      const videoElement = this.$refs.video
      this.videoElement = videoElement

      let flvPlayer = mpegtsjs.createPlayer(
        {
          type: 'flv',
          isLive: true,
          url: this.url,
        },
        {
          enableWorker: false,
          enableStashBuffer: false,
          seekType: 'range',
          stashInitialSize: 128,
          lazyLoad: false,
          lazyLoadMaxDuration: 0.2,
          deferLoadAfterSourceOpen: false,
          liveBufferLatencyChasing: true,
          liveBufferLatencyMaxLatency: 1.5,
          liveBufferLatencyMinRemain: 0.3,
        }
      )
      flvPlayer.attachMediaElement(videoElement)
      this.reloadPlayer = flvPlayer
      this.load_play_player(flvPlayer, videoElement)

      // 视频错误信息回调
      flvPlayer.on(
        mpegtsjs.Events.ERROR,
        (errorType, errorDetail, errorInfo) => {
          // console.log(
          //   '视频错误类型:' + JSON.stringify(errorType),
          //   '报错内容' + errorDetail,
          //   '报错信息' + errorInfo
          // )
          this.status = this.StatusEnum.FAILED
          if (this.url && this.reloadPlayer) {
            flvPlayer = null
            // 视频出错后销毁重新创建
            this.delFlyPlayer()
            this.initPlayer()
          }
        }
      )

      flvPlayer.on(mpegtsjs.Events.STATISTICS_INFO, statisticsInfo => {
        this.decodedFrames = statisticsInfo.decodedFrames
        // console.log(
        //   '🚀 ~ decodedFrames ~ decodedFrames:',
        //   statisticsInfo.decodedFrames
        // )
        // console.log('🚀 ~ initPlayer ~ status:', this.status) // canplay ? 1 : 0
        if (
          statisticsInfo.decodedFrames &&
          this.status !== this.StatusEnum.LOADED
        ) {
          this.reloadPlayer.play()
          // console.log(
          //   '🚀🎦🎦🎦 ~ initPlayer ~ this.reloadPlayer.play(): 🎦🎦🎦🎦'
          // )
          this.status = this.StatusEnum.LOADED
        }
      })
    },

    close() {
      this.delFlyPlayer()
      if (this.reloadPlayer) {
        this.$emit('on-close', this.id, this.isIntranet)
      }
    },

    delFlyPlayer() {
      // console.log('🚀 ~ mpegts delFlyPlayer ~ delFlyPlayer:')
      if (this.reloadPlayer) {
        this.reloadPlayer.pause()
        this.reloadPlayer.unload()
        this.reloadPlayer.detachMediaElement()
        this.reloadPlayer.destroy()
        this.reloadPlayer = null
      }
    },

    load_play_player(flvPlayer, videoElement) {
      flvPlayer.load()
      videoElement.addEventListener('canplay', () => {
        // console.log('🚀 ~ videoElement.addEventListener ~ canplay:')
        this.status = this.StatusEnum.LOADED
        try {
          flvPlayer && flvPlayer.play()
        } catch (error) {}
      })
    },
  },

  beforeDestroy() {
    // console.log('========== mpegts index.vue beforeDestroy===============')
    this.timeId && clearInterval(this.timeId)
    this.delFlyPlayer()
  },
}
</script>

<style lang="scss" scoped>
.flv-video-wrapper {
  height: 100%;
  width: 100%;
  position: relative;

  .video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  // 当前播放时间隐去
  video::-webkit-media-controls-current-time-display {
    display: none !important;
  }
  // 剩余时间隐去
  video::-webkit-media-controls-time-remaining-display {
    display: none !important;
  }
  // 时间轴隐去
  video::-webkit-media-controls-timeline {
    display: none !important;
  }
  .spin-loading {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
</style>
