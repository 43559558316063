<template>
  <Layout class="layout">
    <Header class="layout-header">
      <TopHeader showLogo />
    </Header>
    <Content class="layout-content">
      <slot></slot>
    </Content>
  </Layout>
</template>

<script>
import TopHeader from '../common/_top-header'

export default {
  name: 'HeaderLayout',

  components: { TopHeader },

  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  created() {},

  mounted() {},

  methods: {},
}
</script>

<style lang="scss" scoped>
.layout {
  min-width: 1280px;
  overflow-x: auto;
}
.layout-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
.layout-content {
  min-height: 100vh;
  padding-top: 80px;
}
</style>
