// import { $get, $post, $patch, $put, $delete } from '_library/ajax'
import { $get, $post, $put, $custom } from '_library/ajax'

const list = {
  'get-random': '/securit/random',
  'login-user': '/securit/auth',
  'edit-pwd': '/securit/edit-pwd',
  'get-user-by-id': '/user/get-user-by-id',

  'page-user-list': '/user/page-user-list', // 获取用户列表
  'reset-user-password': '/user/reset-user-password',
  'delete-user-batch': '/user/delete-user-batch',
  'import-user-batch': '/user/import-user-batch',
  'insert-user': '/user/insert-user',
  'update-user': 'user/update-user',
  'insert-user-school-batch': '/user/insert-user-school-batch',
  'update-user-phone-or-user-name': '/user/update-user-phone-or-user-name',

  'page-vo-message-info': '/user/page-vo-message-info',
  'update-message-info-state-batch': '/user/update-message-info-state-batch',
}

const methods = {
  fetchVerifyCode: () => $get('get-random', null, { loading: false }), // 获取验证码
  // loginSystem: data => $post('login-user', data), // 登录
  loginSystem: data =>
    $custom(
      // `/securit/auth?code=${data.code}&codeId=${data.codeId}`,
      `/user/auth?code=${data.code}&codeId=${data.codeId}`,
      'POST',
      data
    ), // 登录
  // loginSystem: data => {
  //   return new Promise(resolve => {
  //     setTimeout(() => {
  //       resolve({
  //         mfaTicket: '111',
  //         phone: '13576652467',
  //       })
  //     }, 2000)
  //   })
  // }, // 登录
  modifyPassword: data => $put('edit-pwd', data), // 修改密码
  getUserInfo: data => $get('get-user-by-id', data),
  getUser: data => $get('get-user-by-id', data),

  resetPassword: data => $post('reset-user-password', data), // 重置密码
  deleteAccount: data => $post('delete-user-batch', data), // 删除账户
  importAccount: data => $post('import-user-batch', data), // 批量导入账户
  addAccount: data => $post('insert-user', data), // 添加账户
  updateAccount: data => $post('update-user', data), // 修改账户
  accoutAllocation: data => $post('insert-user-school-batch', data), // 批量分配学校

  updateUserPhoneOrUserName: data =>
    $post('update-user-phone-or-user-name', data), // 修改用户资料

  pageVoMessageInfo: data => $get('page-vo-message-info', data), // 系统消息

  updateMessageInfoStateBatch: data =>
    $post('update-message-info-state-batch', data), // 修改系统消息状态

  validateTicket: data => $custom(`/sso/shuangliu-login`, 'POST', data), // 验证双流 ticket

  bindDouStreamSystem: data => $custom('/sso/shuangliu-bind', 'POST', data), // 双流系统绑定

  validateSSOIsLogin: data => $custom('/sso/sso-isLogin', 'GET', data), // 验证双流登录

  fetchPhoneCode: data => $custom('/user/send-mfa-code', 'POST', data), // 手机验证码二次验证
  // fetchPhoneCode: data => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       // resolve({
  //       //   code: `${Math.floor(Math.random() * 10000 + 111)}`,
  //       // })
  //       resolve('验证码发送成功')
  //       // throw new Error('接口 502')
  //     }, 2000)
  //   })
  // }, // 手机验证码二次验证

  validateMFACode: data => $custom('/user/mfa-auth', 'POST', data), // 验证手机号码
  // validateMFACode: data => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       resolve({})
  //     }, 3000)
  //   })
  // }, // 验证手机号码
}

export default { list, methods }
