/**
 * * 深拷贝
 * @author fanzhongxu
 */

import getTypeOf from './get_type_of'

/**
 * @description 深拷贝
 * @author fanzhongxu
 * @param {any} obj 待拷贝的对象
 * @returns
 */
export default function deepClone(obj) {
  let result
  const typeName = getTypeOf(obj)

  if (typeName === 'object' || typeName === 'array') {
    result = typeName === 'object' ? {} : []
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        result[key] = deepClone(obj[key])
      }
    }
  } else if (typeName === 'map') {
    result = new Map([])
    for (const [key, value] of obj) {
      result.set(key, deepClone(value))
    }
  } else if (typeName === 'set') {
    result = new Set([])
    for (const value of obj) {
      result.add(deepClone(value))
    }
  } else result = obj

  return result
}
