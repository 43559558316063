export default {
  path: '/set',
  redirect: 'ChannelSet',
  component: () => import('_views/equipmentSet/index'),
  children: [
    {
      path: 'ChannelSet',
      meta: {
        auth: true,
        title: '通道配置',
      },
      component: () => import('_views/equipmentSet/ChannelSet'),
    },
    {
      path: 'PatternSet',
      meta: {
        auth: true,
        title: '模式配置',
      },
      component: () => import('_views/equipmentSet/PatternSet'),
    },
  ],
}
