const state = {
  loginData: {
    isLogin: false,
    userInfo: null, // 用户信息
    ticket: '', // ticket
  },

  spinning: false,
}

export default state
