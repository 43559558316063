<template>
  <div class="repeat-class">
    <Form
      ref="form"
      :label-width="100"
      :model="repeatSetParam"
      :rules="paramsRule"
    >
      <FormItem label="重复设置" prop="isEveryday">
        <Select v-model="repeatSetParam.isEveryday" @on-change="repeatChange"
          ><Option
            v-for="item in repeatSetData"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</Option
          ></Select
        >
      </FormItem>
      <!-- 周选择 -->
      <FormItem v-if="repeatSetParam.isEveryday == '2'" prop="week">
        <div class="select-week-class select-class">
          <section class="label"></section>
          <i-input
            v-model="repeatSetParam.week"
            @click.native.stop="onFocus"
            type="textarea"
            readonly
            placeholder="请选择天数"
            :autosize="{ minRows: 1, maxRows: 2 }"
          >
          </i-input>

          <transition name="slide-down">
            <div class="mark-week" v-if="weekShow">
              <CheckboxGroup @on-change="weekChange" v-model="checkAll">
                <Checkbox
                  v-for="item in weekData"
                  :label="item.id"
                  :key="item.id"
                  >{{ item.name }}</Checkbox
                >
              </CheckboxGroup>
              <div class="bottom-class">
                <section class="ghost-class" @click="onCancel">取消</section>
                <section class="bottom-text" @click="onOk">确定</section>
              </div>
            </div>
          </transition>
        </div>
      </FormItem>
      <!-- 月选择 -->
      <FormItem v-if="repeatSetParam.isEveryday == '3'" prop="months">
        <div class="select-week-class select-class">
          <section class="label"></section>
          <i-input
            v-model="repeatSetParam.months"
            @click.native.stop="onFocus"
            readonly
            placeholder="请选择天数"
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 5 }"
          >
          </i-input>
          <transition name="slide-down">
            <section class="mark-class-moths" v-if="monthShow">
              <section class="top-class">日期</section>
              <section
                :class="{
                  'num-months-class': true,
                  'num-months-class-actived': item.actived,
                }"
                v-for="item in monthsData"
                :key="item.id"
                @click.stop="monthsClick(item)"
              >
                {{ item.name }}
              </section>
              <div class="bottom-class">
                <section class="ghost-class" @click="onCancel">取消</section>
                <section class="bottom-text" @click="onOk">确定</section>
              </div>
            </section>
          </transition>
        </div>
      </FormItem>
      <FormItem label="设置时间" prop="actionTime">
        <timeDateSelect
          class="time-select"
          ref="timeDateSelect"
          :period="period"
          :repeatNum="repeatSetParam.isEveryday"
          @on-date-change="onDateChange"
          @on-time="onTime"
          @on-period-date="onPeriodDate"
          @on-period-time="onPeriodTime"
        ></timeDateSelect>
      </FormItem>
    </Form>
  </div>
</template>
<script>
import timeDateSelect from './components/tiem-date-select'
export default {
  name: 'RepeatSetting',
  components: { timeDateSelect },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    // width: {
    //   type: Number,
    //   default: 500,
    // },
    period: {
      type: Boolean,
      default: () => {
        return false
      },
    },
  },
  data() {
    return {
      repeatSetParam: {
        isEveryday: '',
        week: '',
        months: '',
        dayNum: '',
        actionTime: '',
      },
      paramsRule: {
        isEveryday: {
          required: true,
          message: '请选择重复设置规则',
        },
        week: {
          required: true,
          message: '请选择周',
        },
        months: {
          required: true,
          message: '请选择月',
        },
        actionTime: {
          required: true,
          message: '请选择时间',
        },
      },
      checkAll: [],
      weekShow: false,
      monthShow: false,
      repeatSetData: [
        { id: '0', name: '一次' },
        { id: '1', name: '每天' },
        { id: '2', name: '每周' },
        { id: '3', name: '每月' },
      ], // 重复设置
      weekData: [
        { id: '1', name: '周一' },
        { id: '2', name: '周二' },
        { id: '3', name: '周三' },
        { id: '4', name: '周四' },
        { id: '5', name: '周五' },
        { id: '6', name: '周六' },
        { id: '7', name: '周日' },
      ], // 每周设置
      monthsData: [], // 每月设置
      monthsShowData: [],
    }
  },

  computed: {
    repeatWidth() {
      return (this.width / 1920) * screen.width
    },
  },

  watch: {
    data: {
      handler(obj) {
        for (const key of Object.keys(this.repeatSetParam)) {
          this.repeatSetParam[key] = obj[key] || this.repeatSetParam[key]
        }
        if (obj.actionTime)
          this.$nextTick(() =>
            this.$refs.timeDateSelect.setDate(obj.actionTime)
          )
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 重复设置
    repeatChange() {
      this.repeatSetParam.actionTime = ''
      this.$emit('repeat-change', this.repeatSetParam.isEveryday)
    },
    // 打开 周 && 月 选择
    onFocus() {
      if (this.repeatSetParam.isEveryday === '2') {
        this.weekShow = !this.weekShow
      } else {
        this.monthShow = !this.monthShow
      }
    },
    // 周选择
    weekChange(data) {
      data = data.sort((a, b) => {
        return Number(a) - Number(b)
      })
      let newData = []
      let numData = []
      newData = data.map(map => {
        let obj = {}
        this.weekData.forEach(item => {
          if (item.id === map) {
            obj = item
          }
        })
        return obj.name
      })
      numData = data.map(map => {
        let obj = {}
        this.weekData.forEach(item => {
          if (item.id === map) {
            obj = item
          }
        })
        return obj.id
      })
      this.repeatSetParam.week = newData.join('、')
      this.repeatSetParam.dayNum = numData.join(',')
    },
    // 月选择
    monthsClick(val) {
      this.monthsData.forEach(item => {
        if (val.id === item.id) {
          if (val.actived) {
            this.$set(item, 'actived', false)
          } else {
            this.$set(item, 'actived', true)
          }
        }
      })
      if (val.actived) {
        this.monthsShowData.push(val.id)
      } else {
        this.monthsShowData.forEach((item, index) => {
          if (val.id === item) {
            this.monthsShowData.splice(index, 1)
          }
        })
      }
      this.monthsShowData = this.monthsShowData.sort((a, b) => {
        return a - b
      })
      this.repeatSetParam.months = this.monthsShowData.join('、')
      this.repeatSetParam.dayNum = this.monthsShowData.join(',')
    },
    //
    onCancel() {
      this.checkAll = []
      this.repeatSetParam.week = ''
      this.repeatSetParam.months = ''
      this.monthsData.forEach(item => {
        this.$set(item, 'actived', false)
      })
      this.weekShow = false
      this.monthShow = false
    },
    onOk() {
      this.weekShow = false
      this.monthShow = false
    },
    // 时间选择器 时间点
    onDateChange(data) {
      this.repeatSetParam.actionTime = data
      this.$emit('on-date-change', data)
    },
    onTime(data) {
      this.repeatSetParam.actionTime = data
      this.$emit('on-time', data)
    },
    // 时间段
    onPeriodDate(data1, data2) {
      this.$emit('on-period-date', data1, data2)
    },
    onPeriodTime(data1, data2) {
      this.$emit('on-period-time', data1, data2)
    },
    async getData() {
      const valid = await this.$refs.form.validate()
      if (valid) return this.repeatSetParam
      else return null
    },
  },
  mounted() {},
  created() {
    for (let i = 1; i <= 31; i++) {
      this.monthsData.push({ id: i, name: i })
    }
  },
}
</script>
<style lang="less" scoped>
.select-class {
  width: 90%;
  display: flex;
  align-items: center;
  // .label {
  //   width: 64px;
  //   height: 22px;
  //   font-size: 16px;
  //   font-family: PingFangSC-Medium, PingFang SC;
  //   font-weight: 500;
  //   color: rgba(210, 213, 222, 1);
  //   line-height: 22px;
  // }
  &/deep/ .ivu-select,
  .ivu-select-single {
    width: 330px;
    margin-left: 10px;
  }
  &/deep/ .ivu-input-type-textarea {
    width: 330px;
    textarea.ivu-input {
      min-height: 46px !important;
      border-radius: 23px;
      resize: none;
      line-height: 37px;
    }
  }
}
.select-week-class {
  position: relative;
  .mark-week {
    width: 330px;
    position: absolute;
    left: 0;
    bottom: -430px;
    display: flex;
    align-items: center;
    color: #000000d9; //TODO 下发管理 设置时间功能页 字体颜色
    flex-direction: column;
    background: #ffff; //TODO 下发管理 设置时间 背景颜色
    box-shadow: 0px 2px 10px 0px#d2d5de;
    border-radius: 4px;
    opacity: 0.94;
    filter: blur(0px);
    z-index: 1000;
    &/deep/ .ivu-checkbox-group-item {
      width: 50%;
      margin: 10px 25%;
      .ivu-checkbox {
        float: right;
      }
    }
  }
  .mark-class-moths {
    bottom: 50px;
    right: 5px;
    width: 330px;
    position: absolute;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: #d2d5de;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    background: rgba(41, 55, 88, 1);
    box-shadow: -6px 0px 22px 0px rgba(11, 23, 50, 0.79);
    opacity: 0.94;
    filter: blur(0px);
    z-index: 1000;
  }
  .num-months-class {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    // color: #fff;
    // background: #2d8cf0;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .num-months-class-actived {
    color: #fff;
    background: #0f8dff;
  }
  .top-class {
    width: 330px;
    height: 54px;
    border-bottom: 1px solid #d2d5de;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bottom-class {
    width: 330px;
    height: 54px;
    border-top: 1px solid #d2d5de;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .bottom-text {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      width: 60px;
      height: 30px;
      background: #0f8dff;
      border-radius: 22px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 17px;
      &:hover {
        background: rgba(45, 164, 255, 1);
        cursor: pointer;
      }
    }
    .ghost-class {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      width: 60px;
      height: 30px;
      background: #fff; //TODO 下发管理 设置时间 取消按钮背景颜色
      color: #000000d9;
      border: 1px solid #bcbec2; //TODO 下发管理 设置时间 取消按钮字体颜色
      border-radius: 22px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 17px;
      &:hover {
        color: #0f8dff; //TODO 下发管理 设置时间 点击取消按钮时字体颜色
        border: 1px solid #0f8dff; //TODO 下发管理 设置时间 取消按钮边框颜色
        background: #fff; //TODO 下发管理 设置时间 点击取消按钮时背景颜色
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="scss">
.el-time-spinner__item {
  height: 34px !important;
  line-height: 34px !important;
  background-color: #fff;
}
</style>
