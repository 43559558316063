/* 软件管理 */

import { $get, $post } from '_library/ajax'
const list = {
  // 'send-software': 'application/send-software',
  // 'get-software-by-page': '/application/get-software-by-page',
  'get-send-softwares': '/application/get-send-softwares',
  'get-software-list': '/application/get-software-list',

  // 广告软件配置
  'add-intercept-software': '/application/add-intercept-software',
  'udpate-intercept-software': '/application/udpate-intercept-software',
  'get-intercept-softares': '/application/get-intercept-softares',
  'delete-intercept-software': '/application/delete-intercept-software',

  // 弹窗拦截设置
  'school-get-white-list': '/application/school-get-white-list',
  'school-set-white-list': '/application/school-set-white-list',
  // 软件
  'add-software': '/application/add-software',
  'uninstall-software': '/application/uninstall-software',
  'send-software': '/application/send-software',
  'get-software-by-page': '/application/get-software-by-page',
  'update-software': '/application/update-software',
  'delete-software': '/application/delete-software',
}
const methods = {
  // sendSoftware: data => $post('send-software', data), // 下发软件

  getSendSoftwares: data => $post('get-send-softwares', data), // 获取软件下发记录

  getSoftwareList: data => $get('get-software-list', data), // 获取软件列表

  // getSoftwareByPage: data => $get('get-software-by-page', data), // 分页获取软件

  addInterceptSoftware: data => $post('add-intercept-software', data), // 添加拦截软件

  udpateInterceptSoftware: data => $post('udpate-intercept-software', data), // 编辑拦截软件

  getInterceptSoftares: data => $get('get-intercept-softares', data), // 获取拦截软件列表

  deleteInterceptSoftware: data => $post('delete-intercept-software', data), // 删除拦截软件

  schoolGetWhiteList: data => $get('school-get-white-list', data), // 学校获取白名单

  schoolSetWhiteList: data => $post('school-set-white-list', data), // 学校设置白名单

  addSoftware: data => $post('add-software', data), // 添加软件

  updateSoftware: data => $post('update-software', data), // 编辑软件

  uninstallSoftware: data => $post('uninstall-software', data), // 卸载软件

  sendSoftware: data => $post('send-software', data), // 下发软件

  getSoftwareByPage: data => $get('get-software-by-page', data), // 分页软件

  deleteSoftware: data => $post('delete-software', data), // 分页软件
}
export default {
  list,
  methods,
}
