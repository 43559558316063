/**
 * * 自动注册api
 * * 该该文会自动检测api根目录下的所有js文件，并自动提取list和methods
 */

const list = {}
const methods = {}

const requireApi = require.context('./', false, /[A-Za-z]\w+\.js$/)
requireApi.keys().forEach(fileName => {
  // 排除index.js
  if (fileName.indexOf('index.js') < 0) {
    const apiConfig = requireApi(fileName).default

    Object.assign(list, apiConfig.list)
    const namespace = apiConfig.namespace
    if (namespace) {
      methods[namespace + '_module'] = apiConfig.methods
    } else {
      Object.assign(methods, apiConfig.methods)
    }
  }
})

export const apiList = list
export const apiMethods = methods
