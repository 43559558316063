/**
 * * 慢hash
 * * 由于历史原因，这里md5加密了一万零一次
 * @author fanzhongxu
 */

import md5 from 'blueimp-md5'

/**
 * @description 慢hash
 * @author fanzhongxu
 * @param {*} obj 需要hash的内容
 * @returns md5后的数据
 */
export default function slowHash(str) {
  let s = str
  for (let i = 0; i <= 10000; i++) {
    s = md5(s)
  }
  return s
}
