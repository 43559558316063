// import { $get, $post, $patch, $put, $delete } from '_library/ajax'
import { $get } from '_library/ajax'

const list = {
  'query-school-list': '/yd-school-info/query-yd-school-info-list',
  'statistics-school-count': '/yd-school-info/statistics-school-count',
  'query-statistics-register':
    '/yd-statistics-register/query-statistics-register',
  'query-statistics-attendance':
    '/yd-statistics-attendance/query-statistics-attendance',
  'query-student-count-login': '/yd-statistics_login/query-student-count-login',
  'query-teacher-rate-online': '/yd-statistics_login/query-teacher-rate-online',
  'query-statistics-information':
    '/yd-statistics-information/query-statistics-information',
  'query-statistics-teacher': '/yd-statistics-teacher/query-statistics-teacher',
  'export-school-list': '/yd-school-info/export-school-list',
  'export-teacher-list': '/yd-statistics-teacher/export-teacher-list',
}

const methods = {
  queryRegister: data => $get('query-statistics-register', data), // 注册统计(一个月数据)
  queryAttendance: data => $get('query-statistics-attendance', data), // 查询学生考勤统计
  querySchool: data => $get('query-school-list', data), //  查询学校列表
  schoolInfo: data => $get('statistics-school-count', data), // 统计学校数量
  parentLogin: data => $get('query-student-count-login', data), // 查询家长点击量统计
  teacherOnline: data => $get('query-teacher-rate-online', data), // 查询教师在线率统计
  queryInformation: data => $get('query-statistics-information', data),
  queryTeacherStatistic: data => $get('query-statistics-teacher', data),
  exportSchoolList: data => $get('export-school-list', data),
  exportTeacherList: data => $get('export-teacher-list', data),
}

export default { list, methods }
