/**
 * * 格式化日期
 * @author fanzhongxu
 */

/**
 * @description 日期格式化
 * @author fanzhongxu
 * @param {Date | String | Number} oldDate 待格式化的日期，可以是Date对象也可以是字符串
 * @param {string} [fmt='yyyy年MM月dd日'] 格式化后的格式
 * @returns 格式化后的字符串
 */
export default function formatDate(oldDate, fmt = 'yyyy年MM月dd日') {
  if (!oldDate) return '-'

  let date = new Date()
  if (typeof oldDate === 'string' || typeof oldDate === 'number') {
    date = new Date(+oldDate)
  } else {
    date = oldDate
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  }
  function padLeftZero(str) {
    return ('00' + str).substr(str.length)
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      )
    }
  }
  return fmt
}
