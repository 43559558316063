export default {
  path: '/cos',
  meta: {
    auth: true,
    title: '信发管理',
  },
  // redirect: 'cos/index',
  component: () => import('_views/cos/index'),
  children: [
    {
      path: 'coscon',
      meta: {
        auth: true,
        title: '信发库',
      },
      component: () => import('_views/cos/CosIndex/index'),
      children: [],
    },
    {
      path: 'issued',
      meta: {
        auth: true,
        title: '下发管理',
      },
      component: () => import('_views/cos/Issued/index'),
    },
    {
      path: 'issuedCreate',
      meta: {
        auth: true,
        title: '新建下发',
      },
      component: () => import('_views/cos/Issued/issuedCreate'),
    },
    // {
    //   path: 'issued',
    //   meta: {
    //     auth: true,
    //     title: '',
    //   },
    //   component: () => import('_views/cos/CosIndex/issued'),
    // },
  ],
}
