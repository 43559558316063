// * 售后模块接口
// import { $get, $post, $patch, $put, $delete } from '_library/ajax'
import { $get, $post } from '_library/ajax'

const list = {
  // 需求反馈
  'insert-requirement': '/user/insert-requirement',
  'get-requirement-by-id': '/user/get-requirement-by-id',
  'page-requirement-list': '/user/page-requirement-list',
  'insert-equipment-repairs': '/equipment/insert-equipment-repairs',
  'page-equipment-repairs-list': '/equipment/page-equipment-repairs-list',
  'delete-equipment-repairs-batch': '/equipment/delete-equipment-repairs-batch',
  'update-equipment-repairs-state-batch':
    '/equipment/update-equipment-repairs-state-batch',
  'update-equipment-repairs': '/equipment/update-equipment-repairs',
  'get-equipment-repairs-by-id': '/equipment/get-equipment-repairs-by-id',
  'get-repairs-number-by-user-id': '/equipment/get-repairs-number-by-user-id',
  'get-repairs-time-axis': '/equipment/get-repairs-time-axis',
}

const methods = {
  addRequirement: data => $post('insert-requirement', data), // 添加需求反馈
  getRequirementById: data => $get('get-requirement-by-id', data), // 查询需求反馈
  getRequirementByPage: data => $get('page-requirement-list', data), // 分页查询需求反馈
  insertEquipmentRepairs: data => $post('insert-equipment-repairs', data), // 新增报修单
  getEquipmentRepairsList: data => $get('page-equipment-repairs-list', data), // 查询报修单
  deleteEquipmentRepairs: data => $post('delete-equipment-repairs-batch', data), // 删除报修单
  updateEquipmentRepairsState: data =>
    $post('update-equipment-repairs-state-batch', data), // 修改报修单状态
  updateEquipmentRepairs: data => $post('update-equipment-repairs', data), // 修改报修单
  getEquipmentRepairsById: data => $get('get-equipment-repairs-by-id', data), // 报修单详情
  getRepairsNumberByUserId: data => $get('get-repairs-number-by-user-id', data), // 报修状态数量
  getRepairsTimeAxis: data => $get('get-repairs-time-axis', data), // 时间轴
}

export default { list, methods }
