// import { $get, $post, $patch, $put, $delete } from '_library/ajax'
import { $get } from '_library/ajax'

const list = {
  'get-software-use-ratio': '/equipment/get-software-use-ratio', // 根据名称查询申请单
}

const methods = {
  /* 根据名称查询申请单 */
  getUseRate: data => $get('get-software-use-ratio', data),
}

export default { namespace: 'home', list, methods }
