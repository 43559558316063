// * 资源预览器
<template>
  <div class="assets-viewer">
    <template v-if="value">
      <div class="mask"></div>
      <div class="content">
        <img
          class="close"
          src="~_images/cos/mask_close.svg"
          alt=""
          @click="onClose"
        />
        <!-- 图片预览 -->
        <img class="box" v-if="type === 'img'" :src="src" alt="" />

        <!-- 视频预览 -->
        <VideoPlayer
          class="box"
          v-if="type === 'video'"
          souType="video/mp4"
          :videoUrl="src"
        />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AssetsViewer',

  components: {},

  props: {
    type: {
      required: true,
      type: String,
      validator: value => ['audio', 'img', 'video'].indexOf(value) >= 0,
    },
    src: {
      required: true,
      type: String,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {},

  watch: {
    value: {
      handler(flag) {
        if (flag) {
          document.documentElement.style['overflow-y'] = 'hidden'
        } else {
          document.documentElement.style['overflow-y'] = 'auto'
        }
      },
      immediate: true,
    },
  },

  methods: {
    onClose() {
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.assets-viewer {
  .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9998;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .content {
    position: relative;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9998;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 9999;
      cursor: pointer;
    }
    .box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      max-height: 80vh;
    }
  }
}
</style>
