// * searchInput
<template>
  <i-input
    v-model="keyword"
    :placeholder="placeholder"
    @on-enter="onSearch"
    @on-clear="onSearch()"
    clearable
  >
    <template #suffix>
      <Icon custom="iconfont icon-sousuo pointer" @click.native="onSearch" />
    </template>
  </i-input>
</template>

<script>
export default {
  name: 'SearchInput',

  components: {},

  props: {
    placeholder: {
      type: String,
      default: '请输入搜索内容',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      keyword: '',
    }
  },

  watch: {
    keyword(val, oVal) {
      if (val !== this.value) this.$emit('input', val)
    },
  },

  methods: {
    onSearch() {
      this.$nextTick(() => {
        this.$emit('on-search', this.keyword)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ivu-input-wrapper {
  & /deep/ .ivu-input {
    border-radius: 6px !important; //TODO 信发库 搜索框边框圆角设置
    padding-left: 8px; //TODO 大点的输入框 搜索框 字离左距离
    padding-right: 40px;
    height: 46px;
  }
  & /deep/ .ivu-input-suffix {
    width: 40px;
    .ivu-icon {
      color: $primary-color;
    }
  }
  & /deep/ .ivu-icon {
    height: 46px;
    line-height: 46px;
  }
}
</style>
