// *
<template>
  <ul class="tap-btn-list">
    <li
      v-for="item of data"
      :key="item.value"
      :class="['tap-btn-list-item', { actived: value === item.value }]"
      @click="handleClick(item.value)"
    >
      {{ item.label }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'TabButton',

  components: {},

  props: {
    value: {
      required: true,
      type: String,
    },
    data: {
      type: Array,
      default: () => [
        {
          label: '月',
          value: 'month',
        },
        {
          label: '周',
          value: 'week',
        },
      ],
    },
  },

  data() {
    return {}
  },

  computed: {},

  watch: {},

  created() {},

  mounted() {},

  methods: {
    handleClick(val) {
      this.$emit('input', val)
    },
  },
}
</script>

<style lang="scss" scoped>
.tap-btn-list {
  list-style: none;
  display: flex;
  &-item {
    display: inline-block;
    min-width: 60px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: rgba(163, 174, 193, 1);
    cursor: pointer;
    //TODO 周月背景颜色
    background: rgb(255, 255, 255);

    &:first-of-type {
      border-radius: 100px 0px 0px 100px;
    }
    &:last-of-type {
      border-radius: 0px 100px 100px 0px;
    }
    &.actived {
      background: #0f8dff;
      color: $color-1;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 50%;
        height: 2px;
        background: #0f8dff;
      }
    }
  }
}
</style>
