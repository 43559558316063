// import { $get, $post, $patch, $put, $delete } from '_library/ajax'
import { $get, $post } from '_library/ajax'

const list = {
  'update-equipment-name': '/equipment/update-equipment-name',
  'insert-user-equipment-rel-batch':
    '/equipment/insert-user-equipment-rel-batch',
  'get-equipment-by-page-old': '/equipment/get-equipment-by-page', // 获取设备分页 (旧)
  'get-equipment-by-page': '/equipment/query-equipment', // 获取设备分页 (新)
  // 'get-equipment-by-page-noload': '/equipment/get-equipment-by-page',
  'get-equipment-by-page-noload': '/equipment/query-equipment',
  'delete-group': '/equipment/delete-group',
  'delete-user-equipment-rel-batch':
    '/equipment/delete-user-equipment-rel-batch',
  'get-equipment-info-by-id': '/equipment/get-equipment-info-by-id',
  'add-group': '/equipment/add-group',
  'add-equipment': '/equipment/add-equipment',
  'move-equipments': '/equipment/move-equipments',
  'update-group': '/equipment/update-group',
  'update-equipment': '/equipment/update-equipment',
  'un-band-equipment': '/equipment/un-band-equipment',
  'get-group-tree': '/equipment/get-group-tree-by-school-id',
  'get-eqregion-tree': '/equipment/get-region-tree',
  'get-region-school-group-tree': '/school/get-region-school-group-tree',
  'send-order': '/equipment/send-order',
  'batch-send-orders': '/equipment/batch-send-orders',
  'get-information-list': '/information/get-information-list',
  'get-user-equipment-list': '/equipment/get-user-equipment-list',
  'get-order-by-eq-id': '/equipment/get-order-by-eq-id',
  'get-school-equipment-list': '/equipment/get-school-equipment-list',

  'query-equipment-number': '/equipment/query-equipment-number',
  'get-equipment-by-page-number': '/equipment/get-equipment-by-page-number',
  'get-user-not-equipment': '/equipment/get-user-not-equipment',
  'get-grp-tree': 'equipment/get-group-tree',
  'get-order-by-id': '/equipment/get-order-by-id',
  'get-user-role-school-list': '/school/get-user-role-school-list', // 返回用户角色学校列表  学校列表先调这个接口  选择学校后调
  'get-group-tree-by-school-id': '/equipment/get-group-tree-by-school-id',
  'get-stand-item-list-by-code': '/stand/get-stand-item-list-by-code',
  'delete-equipment': '/equipment/delete-equipment',
  'is-have-sentry': '/equipment/is-have-sentry',

  // * 首页相关
  'homepage-get-top': '/equipment/homepage-get-top',
  'get-board-data': '/equipment/get-board-data',
  'get-equipment-online-num': '/equipment/get-equipment-online-num',
  'get-equipment-normal-num': '/equipment/get-equipment-normal-num',
  'get-equipment-num-and-type': '/equipment/get-equipment-num-and-type',
  'get-online-time-and-use-by-time':
    '/equipment/get-online-time-and-use-by-time',
  'get-online-time-by-month': '/equipment/get-online-time-by-month',
  'get-used-by-month': '/equipment/get-used-by-month',
  'get-year-list': '/equipment/get-year-list',
  'get-data-by-start-and-end': '/equipment/get-data-by-start-and-end',
  // 分页获取任务
  'get-task-by-page': '/equipment/get-task-by-page',
  // 根据任务获取命令列表
  'get-orders-by-task': '/equipment/get-orders-by-task',

  'get-using-order-by-eq-id': '/equipment/get-using-order-by-eq-id',
  'reload-using-orders': '/equipment/reload-using-orders',
  'get-open-and-close-time': '/equipment/get-open-and-close-time',

  // * 设备配置
  // 设备类型
  'get-models': '/equipment/get-models', // 获取设备类型列表
  'add-model': '/equipment/add-model', // 添加设备类型
  'update-model': '/equipment/update-model', // 编辑设备类型
  'delete-model': '/equipment/delete-model', // 删除设备类型
  // 通道
  'get-tunnels': '/equipment/get-tunnels', // 获取通道列表
  'add-tunnel': '/equipment/add-tunnel', // 添加通道
  'update-tunnel': '/equipment/update-tunnel', // 编辑通道
  'delete-tunnels': '/equipment/delete-tunnels', // 删除通道
  // 开机唤醒
  'wake-up': '/equipment/wake-up',
  //* 设备详情
  // 获取安装软件
  'get-install-software-by-eq-id': '/equipment/get-install-software-by-eq-id',
  // 获取最近使用软件
  'get-near-use-software': '/equipment/get-near-use-software',
  // 获取设备异常软件
  'get-error-by-equipment-id': '/equipment/get-error-by-equipment-id',

  'get-equipment-list-by-app': '/equipment/get-equipment-list-by-app',
  'refresh-screenshot': '/information/refresh-screenshot',
  'clean-order': '/equipment/clean-order', // 清除命令
  'information-send-voice': '/information/send-voice', // 巡话-音频
  'equipment-open-control': '/equipment/open-control', // 巡话-音频

  'equipment-get-task-by-id': '/equipment/get-task-by-id', // 根据id获取任务
  'add-push-cache': '/equipment/addPushCache', // 添加设备推流缓存
}

const methods = {
  updateEquipmentName: data => $post('update-equipment-name', data), // 修改设备名
  insertUserEquipmentRelBatch: data =>
    $post('insert-user-equipment-rel-batch', data), // 分配设备
  getEquipmentByPageOld: data => $get('get-equipment-by-page-old', data), // 分页获取设备
  getEquipmentByPageOldNoload: data =>
    $get('get-equipment-by-page-old', data, {
      loading: false,
    }), // 分页获取设备
  getEquipmentByPage: data => $get('get-equipment-by-page', data), // 分页获取设备
  getEquipmentByPageNoload: data =>
    $get('get-equipment-by-page-noload', data, {
      loading: false,
    }), // 分页获取设备
  deleteGroup: data => $post('delete-group', data), // 删除小组
  deleteUserEquipmentRelBath: data =>
    $post('delete-user-equipment-rel-batch', data), // 删除设备
  getEquipmentById: data => $get('get-equipment-info-by-id', data), // 根据id获取设备详情
  addGroup: data => $post('add-group', data), // 添加小组
  addEquipment: data => $post('add-equipment', data), // 添加设备
  queryEquipmentNumber: data =>
    $get('query-equipment-number', data, { loading: false }), // 查询设备数量信息
  getEqNumber: data =>
    $get('get-equipment-by-page-number', data, { loading: false }), // 查询设备数量信息(学校管理和设备管理使用)
  moveEquipment: data => $post('move-equipments', data), // 移动设备
  updateGroup: data => $post('update-group', data), // 编辑小组
  updateEquipment: data => $post('update-equipment', data), // 编辑设备
  unBandEquipment: data => $post('un-band-equipment', data), // 解绑设备
  getGroupTree: data => $get('get-group-tree', data), // 小组树
  getGrTree: data => $get('get-grp-tree', data), // 小组树
  getEqRegionTree: data => $get('get-eqregion-tree', data),
  getRegionSchoolGroup: data => $get('get-user-role-school-list', data),
  getGroupTreeBySchoolId: data =>
    $get('get-group-tree-by-school-id', data, {
      loading: false,
    }),
  deleteEquipment: data => $post('delete-equipment', data), // 删除设备
  getSendOrder: data => $post('send-order', data), // 发送指令
  getAddPushCache: data => $get('add-push-cache', data), // 添加推流设备缓存
  getBatchSendOrder: data => $post('batch-send-orders', data), // 批量发送指令
  getInformationList: data => $get('get-information-list', data), // 获取铃声
  getEquipmentType: data => $get('get-stand-item-list-by-code', data), // 设备列表

  // 首页相关
  getHomepageTop: () => $get('homepage-get-top'), // 获取首页顶部数据
  getOnlineCount: data => $get('get-equipment-online-num', data), // 获取在线设备数量
  getNormalAndErrorCount: data =>
    $get('/equipment/get-equipment-normal-num', data), // 获得正常和异常设备数量
  getEquCountAndType: data => $get('get-equipment-num-and-type', data), // 设备型号与数量
  getOnlineAndUseByDateRange: data =>
    $get('get-online-time-and-use-by-time', data), // 获取开机时长和设备使用率
  getOnlineTimeByYear: data => $get('get-online-time-by-month', data), // 获取首页在线时间月份柱状图
  getUseRateByYear: data => $get('get-used-by-month', data), // 获取使用率折线图
  getYearList: data => $get('get-year-list', data), // 获取年份月份
  getDataByStartAndEnd: data => $get('get-data-by-start-and-end', data), // 根据开始结束时间获取统计
  getBoardData: () => $get('get-board-data'),

  getUserEquipmentList: data => $get('get-user-equipment-list', data), // 获取用户表设备
  getOrderByEqId: data => $get('get-order-by-eq-id', data), // 获取设备日志
  getSchoolEquipmentList: data => $get('get-school-equipment-list', data), // 获取设备
  getOrderById: data => $get('get-order-by-id', data), // 根据id获取命令详情
  getUserNotEquipment: data => $get('get-user-not-equipment', data), // 查询用户未分配设备列表
  getOrdersByTask: data => $get('get-orders-by-task', data), // 下发详情列表
  getUsingOrderByEqId: data =>
    $post('get-using-order-by-eq-id', data, {
      loading: false,
    }), // 下发详情列表
  reloadUsingOrders: data => $post('reload-using-orders', data), // 刷新详情列表
  getOpenAndCloseTime: data => $get('get-open-and-close-time', data), // 获取开关分布时间

  // * 设备配置
  // 设备类型
  getModels: data => $get('get-models', data), // 获取设备类型列表
  addModels: data => $post('add-model', data), // 添加设备类型
  updateModels: data => $post('update-model', data), // 编辑设备类型
  deleteModels: data => $post('delete-model', data), // 删除设备类型

  // 通道
  getTunnels: data => $get('get-tunnels', data), // 获取设备类型列表
  addTunnel: data => $post('add-tunnel', data), // 添加设备类型
  updateTunnel: data => $post('update-tunnel', data), // 编辑设备类型
  deleteTunnels: data => $post('delete-tunnels', data), // 删除设备类型
  // 开机唤醒
  getWakekUp: data => $post('wake-up', data), // 唤醒设备

  getInstallSoftwareByEqId: data => $get('get-install-software-by-eq-id', data), // 根据设备id获取已装软件

  getNearUseSoftwareWakekUp: data => $get('get-near-use-software', data), // 获取已装软件

  getErrorByEquipmentId: data => $get('get-error-by-equipment-id', data), // 获取异常列表

  getEquipmentsByIds: data => $get('get-equipment-list-by-app', data), // get equipments by ids
  getCleanOrder: data => $post('clean-order', data),
  getScreenShot: data => $post('refresh-screenshot', data, { loading: false }), // 获取桌面截图
  sendVoice: data => $post('information-send-voice', data), // 巡课喊话，下发
  getRemoteCode: data => $get('equipment-open-control', data), // 远程控制

  getDistributeTaskById: data => $get('equipment-get-task-by-id', data), // 获取下发详情
  getIsHaveSentry: data => $get('is-have-sentry', data), // 判断是否有哨兵
}

export default {
  list,
  methods,
}
