<template>
  <div class="layout-aside-menu">
    <div class="header_title">创维集控系统</div>

    <Menu
      class="menu"
      mode="vertical"
      :active-name="curUrl"
      :open-names="openNames"
      accordion
    >
      <template v-for="menu of filterMeus">
        <MenuItem
          v-if="!menu.children"
          :key="menu.title"
          :name="menu.url"
          :to="{ path: menu.url }"
        >
          <Icon v-if="menu.icon" :custom="menu.icon" />
          <span>{{ menu.title }}</span>
        </MenuItem>
        <Submenu v-else :key="menu.title" :name="menu.url">
          <template #title>
            <Icon v-if="menu.icon" :custom="menu.icon" />
            <span>{{ menu.title }}</span>
          </template>
          <template v-for="item of menu.children">
            <Submenu v-if="item.children" :key="item.title" :name="item.url">
              <template #title>
                <Icon v-if="item.icon" :custom="item.icon" />
                <span>{{ item.title }}</span>
              </template>
              <template v-for="iw of item.children">
                <Submenu v-if="iw.children" :key="iw.title" :name="iw.url">
                  <template #title>
                    <Icon v-if="iw.icon" :custom="iw.icon" />
                    <span>{{ iw.title }}</span>
                  </template>

                  <MenuItem
                    v-for="iq of iw.children"
                    :key="iq.title"
                    :name="iq.url"
                    :to="{ path: iq.url }"
                  >
                    <Icon
                      v-if="iq.icon"
                      :style="{ color: '#0F8DFF' }"
                      :custom="iq.icon"
                    />
                    <span class="submenu-title">{{ iq.title }}</span>
                  </MenuItem>
                </Submenu>

                <MenuItem
                  v-else
                  :key="iw.title"
                  :name="iw.url"
                  :to="{ path: iw.url }"
                >
                  <Icon
                    v-if="iw.icon"
                    :style="{ color: '#0F8DFF' }"
                    :custom="iw.icon"
                  />
                  <span class="submenu-title">{{ iw.title }}</span>
                </MenuItem>
              </template>
            </Submenu>

            <MenuItem
              v-else
              :key="item.title"
              :name="item.url"
              :to="{ path: item.url }"
            >
              <Icon
                v-if="item.icon"
                :style="{ color: '#0F8DFF' }"
                :custom="item.icon"
              />
              <span class="submenu-title">{{ item.title }}</span>
            </MenuItem>
          </template>
        </Submenu>
      </template>
    </Menu>
  </div>
</template>

<script>
import menus from '_enums/menus'
import { mapState } from 'vuex'
import { roleMixins } from '_mixins'
import { rolesEnum } from '_enums'

export default {
  name: 'AsideMenu',

  components: {},

  mixins: [roleMixins],

  props: {},

  data() {
    return {
      menus,
    }
  },

  computed: {
    ...mapState({
      userName: state => state.loginData?.userInfo?.userName || '用户',
      loginisMessage: state => state.loginData?.userInfo?.isMessage,
      messageNum: state => state.loginData?.userInfo?.messageNum,
    }),

    filterMeus() {
      function recursion(data, role) {
        if (!data) return undefined
        const result = []
        for (const menu of data) {
          if (!menu.roles || menu.roles.includes(role)) {
            result.push({
              ...menu,
              ...{ children: recursion(menu.children, role) },
            })
          }
        }
        return result
      }
      return recursion(this.menus, rolesEnum[this.roleValue])
    },

    openNames() {
      return this.filterMeus.reduce((t, c) => {
        const curUrl = this.curUrl
        // console.log(t, c, '+++++++++++++++++++++')
        if (c.children) {
          const hasThis = c.children.map(one => one.url).includes(curUrl)
          hasThis && t.push(c.url)
          c.children.forEach(two => {
            if (two.children) {
              const hasTow = two.children.map(ite => ite.url).includes(curUrl)

              hasTow && t.push(c.url)
              hasTow && t.push(two.url)
              two.children.forEach(three => {
                if (three.children) {
                  const hasThree = three.children
                    .map(it => it.url)
                    .includes(curUrl)

                  hasThree && t.push(c.url)
                  hasThree && t.push(two.url)
                  hasThree && t.push(three.url)
                }
              })
            }
            //     let hasTow = item.children.map(ite => ite.url).includes(curUrl)
            //     hasTow && t.push(c.url)
          })
        }
        return t
      }, [])
    },
  },

  watch: {
    $route: {
      handler(route) {
        const path = route.path

        this.curUrl = this.getCurUrl(path, this.filterMeus)
      },
      immediate: true,
    },
  },

  created() {},

  mounted() {},

  methods: {
    getCurUrl(path, menus) {
      let result = ''
      for (const menu of menus) {
        if (menu.children && menu.children.length > 0)
          result = this.getCurUrl(path, menu.children)
        else if (path.indexOf(menu.url) === 0) result = menu.url

        if (result) break
      }
      console.log(result, 'cururl')
      return result
    },
  },
}
</script>

<style lang="scss">
.layout-aside-menu {
  height: 100%;
  .header_title {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    padding: 30px 25px 0 25px;
    text-align: center;
  }

  .ivu-menu {
    width: auto !important;
    background: transparent;
    border: none;
    height: 91%;
    overflow-x: auto;
    &.menu {
      margin-top: 29px;
    }

    &::after {
      display: none;
    }

    &-item-active:not(.ivu-menu-submenu) {
      background: #0f8dff !important; //TODO 集控侧边栏选择框背景颜色
      color: #ffffff !important;
      &::after {
        display: none !important;
      }
    }

    &-item,
    &-submenu-title {
      &,
      .iconfont {
        color: rgba(166, 171, 189, 1) !important;
      }
      .iconfont {
        vertical-align: top;
        margin-right: 20px;
      }

      &:hover {
        color: #ffffff;
        background-color: rgba(28, 39, 64, 1);
      }
    }

    &-item-active {
      // .ivu-menu-submenu-title {
      //   background: linear-gradient(
      //     90deg,
      //     rgba(52, 90, 154, 1) 0%,
      //     rgba(35, 47, 75, 1) 100%
      //   );
      // }
      .iconfont {
        color: #fff !important; //TODO 集控侧边栏选中图标背景颜色
      }
    }

    .ivu-menu-submenu {
      .ivu-menu-item {
        height: 49px !important;
      }
      .ivu-menu-item-active {
        background-image: url('~_images/hover.png') !important;
        background-repeat: no-repeat !important;

        background-position: 23px 18px !important;

        transition: none !important;

        span {
          color: #fff !important; //TODO: 集控侧边栏选择字体颜色
        }
      }

      .ivu-menu-item {
        padding-left: 63px !important;
      }
    }
  }
}
</style>
