const equipment = {
  namespaced: true,
  state: {
    userIdSetting: {
      userId: null,
    },
    deviceLogs: {
      id: '',
      eqContentDatail: '',
    },
  },
  getters: {},
  mutations: {
    setUserId(state, payload) {
      state.userIdSetting = payload
    },
    setEqContentDatail(state, payload) {
      state.deviceLogs = payload
    },
  },
  actions: {},
}

export default equipment
