/**
 * * 全局过滤器
 */

const filters = {
  /* 时间戳转日期 */
  formatDate: function(oldDate, fmt = 'yyyy年MM月dd日') {
    if (!oldDate) return '-'
    let date = new Date()
    if (typeof oldDate === 'string' || typeof oldDate === 'number') {
      date = new Date(+oldDate)
    } else {
      date = oldDate
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length)
      )
    }
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
    }
    function padLeftZero(str) {
      return ('00' + str).substr(str.length)
    }
    for (const k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        const str = o[k] + ''
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? str : padLeftZero(str)
        )
      }
    }
    return fmt
  },

  formatCount(count) {
    count = +count || 0
    if (count < 1000) return count
    else if (count < 10000) return `${(count / 1000).toFixed(1)}K`
    else return `${(count / 10000).toFixed(1)}W`
  },

  formatAudioTime: seconds => {
    seconds = Math.ceil(seconds)
    const oneMin = 60

    const min = ~~(seconds / oneMin) || '00'
    const sec = seconds - min * 60

    return `${extendStr(min)}:${extendStr(sec)}`

    function extendStr(str) {
      str = str + ''
      if (str.length < 2) return `0${str}`
      else return str
    }
  },
}

export default filters
