// /**
//  * * 项目入口文件

import App from './app.vue'
import Vue from 'vue'
import router from '_router'
import store from '_store'

// 引入自定义全局样式
import '_style/index.scss'
import '_style/fonts/iconfont.css'

// viewui
import '_plugins'

// 引入自定义过滤器
import filters from '@/filters'

// 引入自定义指令
import directives from '@/directives'

// 注册全局mixin
import { globalMixins } from '@/mixins'
import queryEncrypt from '_mixins/query_encrypt'

// 全局注册组件
import components from '_components'
// 全局注册复制插件
import VueClipboard from 'vue-clipboard2'

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

if (process.env.NODE_ENV === 'development') require('_mock')
Vue.use(VueClipboard)
// 注册过滤器和自定义指令
Object.keys(filters).forEach(key => Vue.filter(key, filters[key]))
Object.keys(directives).forEach(key => Vue.directive(key, directives[key]))

// 注册全局混入
Vue.mixin(globalMixins)
Vue.mixin(queryEncrypt)

// 注册全局组件
Object.keys(components).forEach(key => Vue.component(key, components[key]))

/* Vue 全局配置 */
Vue.config.productionTip = false

const vueInstance = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

store.dispatch('getUserInfo').then(() => vueInstance)

export default vueInstance
