<template>
  <div class="flv-video-wrapper">
    <video
      class="video"
      v-show="status === StatusEnum.LOADED"
      ref="video"
      muted
      controls
      disablePictureInPicture
    ></video>
    <Empty v-show="status === StatusEnum.FAILED" v-bind="emptyInfo" />
    <Spin
      class="spin-loading"
      size="large"
      fix
      v-show="status === StatusEnum.LOADING"
    >
      <Icon type="ios-loading" size="18" class="spin-icon-load"></Icon>
      <div>加载中</div>
    </Spin>
  </div>
</template>

<script>
import flvjs from 'flv.js'

const StatusEnum = {
  LOADING: '0',
  LOADED: '1',
  FAILED: '2',
}

export default {
  name: 'FlvVideo',

  components: {},

  props: {
    id: {
      type: String,
    },
    url: {
      type: String,
    },
    emptyInfo: {
      type: Object,
      default: () => ({
        size: 'large',
        // imgSrc: ''
        text: '暂时无法获取桌面',
      }),
    },
  },

  data() {
    return {
      StatusEnum,
      status: StatusEnum.LOADING,
      flvPlayer: null,
    }
  },

  computed: {},

  watch: {
    url(url) {
      if (url) {
        this.initPlayer()
      } else this.close()
    },
  },

  created() {},

  mounted() {
    if (this.url) this.initPlayer()
  },

  methods: {
    initPlayer() {
      this.status = StatusEnum.LOADING
      const videoElement = this.$refs.video
      const flvPlayer = flvjs.createPlayer({
        type: 'flv',
        isLive: true,
        url: this.url,
      })
      this.flvPlayer = flvPlayer
      flvPlayer.attachMediaElement(videoElement)

      flvPlayer.on(flvjs.Events.ERROR, () => {
        this.status = StatusEnum.FAILED
      })

      videoElement.addEventListener('loadedmetadata', () => {
        this.status = StatusEnum.LOADED
        flvPlayer.play()
      })
      flvPlayer.load()
    },

    close() {
      const player = this.flvPlayer
      if (player) {
        player.pause()
        player.unload()
        player.detachMediaElement()
        player.destroy()
        this.flvPlayer = null
        this.$emit('on-close', this.id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.flv-video-wrapper {
  height: 100%;
  width: 100%;
  position: relative;

  .video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  // 当前播放时间隐去
  video::-webkit-media-controls-current-time-display {
    display: none !important;
  }
  // 剩余时间隐去
  video::-webkit-media-controls-time-remaining-display {
    display: none !important;
  }
  // 时间轴隐去
  video::-webkit-media-controls-timeline {
    display: none !important;
  }
  .spin-loading {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
</style>
