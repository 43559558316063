<template>
  <div class="no_data">
    <div class="no_data_main">
      <img src="~_images/cos/filebg.png" />
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'noData',

  components: {},

  data() {
    return {}
  },

  props: {
    text: {
      type: String,
      default: () => {
        return '暂无数据'
      },
    },
  },

  computed: {},

  methods: {},

  created() {},

  mounted() {},

  destroyed() {},

  watch: {},
}
</script>

<style scoped lang="less">
.no_data {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &_main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 400px;
    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000d9; //TODO 下发管理 图片下方字体颜色
      opacity: 0.5; //TODO 策略库下方 透明度
      line-height: 20px;
      margin-top: 48px;
    }
    img {
      // width: 150px;
      // height: 150px;
    }
  }
}
</style>
